import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

const ItemArticuloModalVenta = ({
    articulo,
    setCodigoAndCantidad,
    inputTotalRef,
}) => {
    const handleSetCodigo = () => {
        setCodigoAndCantidad(articulo.articulo.codigo)
        inputTotalRef.current.focus()
    }

    return (
        <tr>
            <th scope="row">{articulo.articulo.codigo}</th>
            <td>{articulo.articulo.nombre}</td>
            <td>
                $
                {articulo.articulo.precioFinal
                    ? articulo.articulo.precioFinal.toFixed(2)
                    : articulo.articulo.precioFinalCalculado.toFixed(2)}
            </td>
            <td>{articulo.cantidad}</td>
            <td>
                <button
                    className="btn text-danger"
                    data-dismiss="modal"
                    onClick={() => {
                        handleSetCodigo()
                    }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemArticuloModalVenta)
