import axios from 'axios'
import { url_node } from './global'

export const createTicket = async (body) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: token,
    }
    const data = {
        resource: body,
    }
    const res = await axios.post(`${url_node}/ticket`, data, { headers })
    return res
}
