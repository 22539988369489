import {
    GET_PEDIDOS,
    GET_PEDIDO,
    ADD_PEDIDO,
    EDIT_PEDIDO,
    DELETE_PEDIDO,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    pedidos: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PEDIDOS:
            return {
                ...state,
                pedidos: action.payload,
            }
        case GET_PEDIDO:
            return {
                ...state,
                pedido: action.payload,
            }
        case ADD_PEDIDO:
            return {
                ...state,
                pedidos: [...state.pedidos, action.payload],
            }
        case EDIT_PEDIDO:
            return {
                ...state,
                pedidos: state.pedidos.map((pedido) =>
                    pedido.idPedido === action.payload.idPedido
                        ? (pedido = action.payload)
                        : pedido
                ),
            }
        case DELETE_PEDIDO:
            return {
                ...state,
                pedidos: state.pedidos.filter(
                    (pedido) => pedido.idPedido !== action.payload
                ),
            }
        default:
            return state
    }
}
