export const determinarTipoComprobante = (
    condicionFiscal,
    setTipoComprobante
) => {
    switch (condicionFiscal) {
        case 1:
            setTipoComprobante(1)
            break
        case 5:
        case 6:
        default:
            setTipoComprobante(3)
            break
    }
}
