import { combineReducers } from 'redux'

// reducers
import articulosReducer from './articulosReducer'
import departamentoReducer from './departamentoReducer'
import familiaReducer from './familiaReducer'
import marcaReducer from './marcaReducer'
import unidadMedidadReducer from './unidadMedidaReducer'
import comprobanteReducer from './comprobanteReducer'
import tarjetaReducer from './tarjetaReducer'
import listaPrecioReducer from './listaPrecioReducer'
import ofertaReducer from './ofertaReducer'
import personasReducer from './personasReducer'
import clientesReducer from './clientesReducer'
import cargoReducer from './cargoReducer'
import sucursalReducer from './sucursalReducer'
import empleadoReducer from './empleadoReducer'
import cuentaCorrienteReducer from './cuentaCorrienteReducer'
import roleMenuReducer from './roleMenuReducer'
import roleReducer from './roleReducer'
import medioPagoReducer from './medioPagoReducer'
import ventaReducer from './ventaReducer'
import pedidoReducer from './pedidoReducer'
import stockGeneralReducer from './stockGeneralReducer'
import tokenReducer from './tokenReducer'
import proveedorReducer from './proveedorReducer'

export default combineReducers({
    articulos: articulosReducer,
    departamentos: departamentoReducer,
    familias: familiaReducer,
    marcas: marcaReducer,
    medidas: unidadMedidadReducer,
    comprobantes: comprobanteReducer,
    tarjetas: tarjetaReducer,
    listasPrecios: listaPrecioReducer,
    ofertas: ofertaReducer,
    personas: personasReducer,
    clientes: clientesReducer,
    cargos: cargoReducer,
    sucursales: sucursalReducer,
    empleados: empleadoReducer,
    cuentasCorrientes: cuentaCorrienteReducer,
    menus: roleMenuReducer,
    roles: roleReducer,
    mediosPagos: medioPagoReducer,
    ventas: ventaReducer,
    pedidos: pedidoReducer,
    stockGenerales: stockGeneralReducer,
    token: tokenReducer,
    proveedores: proveedorReducer,
})
