import React, { Fragment, useState } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// redux
import { useDispatch } from 'react-redux'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileInvoiceDollar,
    faPlus,
    faKeyboard,
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// actions
import { AddComprobante } from '../../Actions/ComprobanteAction'

// services
import { saveComprobante } from '../../Services/comprobanteService'

function AddTipoComprobante({ history }) {
    const [nombre, setNombre] = useState('')
    const [codigo, setCodigo] = useState(0)

    const dispatch = useDispatch()

    const addComprobanteSubmit = (e) => {
        e.preventDefault()
        // validaciones

        // creamos el departamento
        let comprobante = {
            nombre: nombre,
            codigo: codigo,
            activo: true,
        }

        saveComprobante(comprobante)
            .then((res) => {
                dispatch(AddComprobante(res))
                history.goBack()
            })
            .catch((error) => console.log('error al guardar'))
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faFileInvoiceDollar}
                        title={'Tipo Comprobantes'}
                    />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={addComprobanteSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileInvoiceDollar}
                                        />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. FACTURA A, FACTURA B..."
                                        onChange={(e) =>
                                            setNombre(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Codigo</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faKeyboard} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="codigo"
                                        placeholder="Ej. 1, 2, 100"
                                        onChange={(e) =>
                                            setCodigo(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear
                                Comprobante
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddTipoComprobante)
