import axios from 'axios'
import { url } from './global'

export const getAllOfertasPage = async (page, data) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.post(`${url}/ofertas/page/${page}`, data, { headers })
    return res
}

export const getOfertaByArticulo = async (codigo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/ofertas/articulo/${codigo}`, {
        headers,
    })
    return res
}

export const getByIdOferta = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/ofertas/${id}`, { headers })
    return res
}

export const editByIdOferta = async (oferta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/v2/ofertas/${oferta.id}`, oferta, {
        headers,
    })
    return res
}

export const deleteByIdOferta = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/ofertas/${id}`, { headers })
    return res
}

export const saveOferta = async (oferta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/ofertas`, oferta, { headers })
    return res
}

export const recoverOfertaDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/ofertas/${id}/recover`,
        {},
        { headers }
    )
    return res
}

export const getAllOfertas = async (
    activo,
    page,
    nombre,
    sucursal,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/ofertas?${
            nombre ? `nombre=${nombre}&` : ''
        }sucursal=${sucursal}&activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}
