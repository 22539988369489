import React from 'react'

import { withRouter } from 'react-router-dom'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const BotonBack = ({ history }) => {
    return (
        <button
            type="button"
            className="btn btn-outline-warning btn-lg"
            onClick={() => {
                history.goBack()
            }}
        >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Volver
        </button>
    )
}

export default withRouter(BotonBack)
