import axios from 'axios'
import { url } from './global'

export const getAllCuentasCorrientesPage = async (
    activo,
    page,
    nombre,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/cuenta-corriente/proveedores?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const saveCuentasCorriente = async (cuenta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(
        `${url}/v2/cuenta-corriente/proveedores`,
        cuenta,
        { headers }
    )
    return res
}

export const editByIdCuentasCorriente = async (cuenta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/cuenta-corriente/proveedores/${cuenta.id}`,
        cuenta,
        { headers }
    )
    return res
}

export const deleteCuentasCorrienteProveedor = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(
        `${url}/v2/cuenta-corriente/proveedores/${id}`,
        { headers }
    )
    return res
}

export const recoverCuentasCorrienteProveedor = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/cuenta-corriente/proveedores/${id}/recover`,
        {},
        { headers }
    )
    return res
}
