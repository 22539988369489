import axios from 'axios'
import { url } from './global'

export const getAllCompras = async (
    activo,
    page,
    nombre,
    sucursal,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/compras?${
            nombre ? `nombre=${nombre}&` : ''
        }sucursal=${sucursal}&activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const saveCompra = async (data) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.post(`${url}/v2/compras`, data, { headers })
    return res
}

// REPORTES COMPRAS
export const getComprasReportes = async (sucursal, fechad, fechah) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/compras/sucursal/${sucursal}/reportes/${fechad}/${fechah}`,
        { headers }
    )
    return res
}

export const getComprasReportesByProveedor = async (
    sucursal,
    proveedor,
    fechad,
    fechah
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/compras/proveedor/${proveedor}/sucursal/${sucursal}/reportes/${fechad}/${fechah}`,
        { headers }
    )
    return res
}

export const deleteByIdCompra = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/compras/${id}`, { headers })
    return res
}

export const recoverCompraDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/compras/${id}/recover`,
        {},
        { headers }
    )
    return res
}
