import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { deleteByIdPuntoVentas } from '../../Services/puntoVentaService'

const ItemPuntoVenta = ({ puntoVenta, history, setIsLoad }) => {
    const btnEliminarPuntoVenta = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara un punto de venta',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdPuntoVentas(id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'El punto de venta fue eliminado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{puntoVenta.id}</th>
            <td>{puntoVenta.nombre}</td>
            <td>{puntoVenta.ip || 'NO'}</td>
            <td>{puntoVenta.sucursal.nombre}</td>
            <td>
                {puntoVenta.datoFiscal
                    ? puntoVenta.datoFiscal.razonSocial
                    : 'NO'}
            </td>
            <td>
                <button
                    className="btn text-danger"
                    onClick={() => {
                        history.push(`/edit-punto-venta/${puntoVenta.id}`, {
                            puntoVenta: puntoVenta,
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button
                    className="btn text-primary"
                    onClick={() => btnEliminarPuntoVenta(puntoVenta.id)}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemPuntoVenta)
