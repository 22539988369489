import React, { Fragment, useRef } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBox,
    faCalendarAlt,
    faDonate,
    faEdit,
    faMoneyBill,
    faPercentage,
    faSearch,
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// services
import { editByIdOferta } from '../../Services/ofertaService'
import Header from '../Header/Header'

// alert
import Swal from 'sweetalert2'

const EditOferta = ({ history, location }) => {
    const oferta = location.state.oferta

    let nombreRef = useRef('')
    let fechaFinRef = useRef('')
    let porcentajeDescuentoRef = useRef('')
    let precioFinalRef = useRef('')
    let promocionCantidadRef = useRef('')

    const editarOfertaSubmit = (e) => {
        e.preventDefault()

        let ofertaUpdated = {
            id: oferta.id,
            articulo: oferta.articulo,
            nombre: nombreRef.current.value.trim(),
            porcentajeDescuento: porcentajeDescuentoRef.current.value,
            precioFinal: precioFinalRef.current.value,
            promocionCantidad: promocionCantidadRef.current.value,
            fechaFinOferta: fechaFinRef.current.value,
        }

        editByIdOferta(ofertaUpdated)
            .then((res) => {
                history.goBack()
            })
            .catch((error) => {
                Swal.fire({
                    title: 'No se pudo crear la oferta',
                    text: 'verifique los datos del formulario',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faDonate} title={'Ofertas'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={editarOfertaSubmit}>
                        <div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">
                                        Articulo
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                        <input
                                            type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            defaultValue={
                                                oferta.articulo.nombre
                                            }
                                            disabled
                                            className="form-control"
                                            placeholder="Ingrese el codigo de un articulo"
                                        />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputFechaFin">
                                        Fecha Fin
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCalendarAlt}
                                            />
                                        </span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="inputFechaFin"
                                            name="fechaFinOferta"
                                            defaultValue={
                                                oferta.fechaFinOferta.split(
                                                    'T'
                                                )[0]
                                            }
                                            ref={fechaFinRef}
                                        />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">
                                        Nombre oferta
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon icon={faDonate} />
                                        </span>
                                        <input
                                            type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Lacteos, Gaseosas..."
                                            defaultValue={oferta.nombre}
                                            ref={nombreRef}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputPrecioFinal">
                                        Precio Final
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon
                                                icon={faMoneyBill}
                                            />
                                        </span>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            id="inputPrecioFinal"
                                            name="precioFinal"
                                            placeholder="Ingrese precio final"
                                            defaultValue={oferta.precioFinal}
                                            ref={precioFinalRef}
                                        />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputPorcentajeDescuento">
                                        Porcentaje Descuento
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon
                                                icon={faPercentage}
                                            />
                                        </span>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            id="inputPorcentajeDescuento"
                                            name="porcentajeDescuento"
                                            placeholder="Ingrese porcetaje de descuento"
                                            defaultValue={
                                                oferta.porcentajeDescuento
                                            }
                                            ref={porcentajeDescuentoRef}
                                        />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="inputCantidadArticulo">
                                        Cantidad de Articulos
                                    </label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon icon={faBox} />
                                        </span>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            id="inputCantidadArticulo"
                                            name="promocionCantidad"
                                            placeholder="Ingrese la cantidad de articulos"
                                            defaultValue={
                                                oferta.promocionCantidad
                                            }
                                            ref={promocionCantidadRef}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-outline-success"
                                >
                                    <FontAwesomeIcon icon={faEdit} /> Editar
                                    Oferta
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditOferta)
