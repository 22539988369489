import {
    MOSTRAR_DEPARTAMENTOS,
    ADD_DEPARTAMENTO,
    EDIT_DEPARTAMENTO,
    GET_DEPARTAMENTO,
    DELETE_DEPARTAMENTO,
} from './Types'

export const mostrarDepartamentos = (res) => {
    return {
        type: MOSTRAR_DEPARTAMENTOS,
        payload: res.data,
    }
}

export const AddDepartamentos = (res) => {
    return {
        type: ADD_DEPARTAMENTO,
        payload: res.data,
    }
}

export const EditarDepartamento = (res) => {
    return {
        type: EDIT_DEPARTAMENTO,
        payload: res.data,
    }
}

export const GetDepartamento = (res) => {
    return {
        type: GET_DEPARTAMENTO,
        payload: res.data,
    }
}

export const DeleteDepartamento = (id) => {
    return {
        type: DELETE_DEPARTAMENTO,
        payload: id,
    }
}
