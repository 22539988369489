import {
    GET_CARGOS,
    ADD_CARGO,
    EDIT_CARGO,
    GET_CARGO,
    DELETE_CARGO,
} from './Types'

export const GetCargos = (res) => {
    return {
        type: GET_CARGOS,
        payload: res.data,
    }
}

export const AddCargo = (res) => {
    return {
        type: ADD_CARGO,
        payload: res.data,
    }
}

export const EditarCargo = (res) => {
    return {
        type: EDIT_CARGO,
        payload: res.data,
    }
}

export const GetCargo = (res) => {
    return {
        type: GET_CARGO,
        payload: res.data,
    }
}

export const DeleteCargo = (id) => {
    return {
        type: DELETE_CARGO,
        payload: id,
    }
}
