import React, { Fragment, useEffect, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCopyright,
    faPlus,
    faSearch,
    faTrash,
} from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import ItemMarca from './ItemMarca'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// services
import { getAllMarcas } from '../../Services/marcaService'

// alert swal
import Swal from 'sweetalert2'

const Marcas = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const [isLoad, setIsLoad] = useState(true)
    const [marcas, setMarcas] = useState([])

    //Pagination
    const [page, setPage] = useState(0)
    const [totalRegister, setTotalRegister] = useState(0)
    const [totalPage, setTotalPage] = useState(0)

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        if (isLoad) {
            getAllMarcas(true, 0)
                .then((res) => {
                    setMarcas(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const goToDeleted = () => {
        history.push('/marcas-eliminadas')
    }

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }
        getAllMarcas(true, page + 1)
            .then((res) => {
                setPage(page + 1)
                setMarcas(res.data.content)
                setTotalRegister(res.data.totalElements)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }
        getAllMarcas(true, page - 1)
            .then((res) => {
                setPage(page - 1)
                setMarcas(res.data.content)
                setTotalRegister(res.data.totalElements)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const handleBuscador = (e) => {
        if (e.key === 'Enter') {
            let data = e.target.value
            getAllMarcas(true, 0, data)
                .then((res) => {
                    setPage(0)
                    setMarcas(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faCopyright} title={'Marcas'} />
                </div>

                <div className="bg-gris p-3">
                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-outline-success btn-lg mr-2"
                                onClick={() => {
                                    history.push('/add-marca')
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear
                            </button>
                            <button
                                className="btn btn-outline-danger btn-lg"
                                onClick={goToDeleted}
                            >
                                <FontAwesomeIcon icon={faTrash} /> Eliminados
                            </button>
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="nombre"
                                    placeholder="Buscar"
                                    onKeyPress={(e) => handleBuscador(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-outline-info"
                                onClick={() => previousPage()}
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual:{' '}
                            {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-outline-info"
                                onClick={() => nextPage()}
                                aria-label="Next"
                            >
                                <span aria-hidden="true">
                                    Siguiente &raquo;
                                </span>
                            </button>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Ganancia</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {marcas.map((marca) => (
                                <ItemMarca
                                    key={parseInt(marca.id)}
                                    marca={marca}
                                    setIsLoad={setIsLoad}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(Marcas)
