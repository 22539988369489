import {
    GET_EMPLEADOS,
    GET_EMPLEADO,
    ADD_EMPLEADO,
    EDIT_EMPLEADO,
    DELETE_EMPLEADO,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    empleados: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_EMPLEADOS:
            return {
                ...state,
                empleados: action.payload,
            }
        case GET_EMPLEADO:
            return {
                ...state,
                empleado: action.payload,
            }
        case ADD_EMPLEADO:
            return {
                ...state,
                empleados: [...state.empleados, action.payload],
            }
        case EDIT_EMPLEADO:
            return {
                ...state,
                empleados: state.empleados.map((empleado) =>
                    empleado.idEmpleado === action.payload.idEmpleado
                        ? (empleado = action.payload)
                        : empleado
                ),
            }
        case DELETE_EMPLEADO:
            return {
                ...state,
                empleados: state.empleados.filter(
                    (empleado) =>
                        empleado.idEmpleado.persona.idPersona !== action.payload
                ),
            }
        default:
            return state
    }
}
