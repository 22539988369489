import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { deleteByIdFamilias } from '../../Services/familiaService'

const ItemFamilia = ({ familia, history, setIsLoad }) => {
    const deleteFamilia = (familia) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara una familia!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                deleteByIdFamilias(familia.id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'La familia fue eliminado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr key={parseInt(familia.id)}>
            <th scope="row">{familia.id}</th>
            <td>{familia.nombre}</td>
            <td>{familia.departamento.nombre}</td>
            <td>{familia.ganancia}%</td>
            <td>
                <button
                    className="btn text-danger"
                    onClick={() => {
                        history.push(`/edit-familia/${familia.id}`, {
                            familia: familia,
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button
                    className="btn text-primary"
                    onClick={() => {
                        deleteFamilia(familia)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemFamilia)
