import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { recoverByIdCuentasCorriente } from '../../Services/cuentaCorrienteClienteService'

const ItemCuentaCorrienteEliminada = ({
    cuentaCorriente,
    history,
    setIsLoad,
}) => {
    const isDateNull = (fecha) => {
        if (fecha === null) return '-'
        else {
            let fechaFinal = fecha.split('T')[0]
            return fechaFinal
        }
    }

    const recoverCuentaCorriente = (cuentaCorriente) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                recoverByIdCuentasCorriente(cuentaCorriente.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'La cuenta corriente fue recuperada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                    })
            }
        })
    }

    return (
        <tr key={parseInt(cuentaCorriente.id)}>
            <th scope="row">{cuentaCorriente.id}</th>
            <td>{cuentaCorriente.cliente.id.persona.nombre}</td>
            <td>{cuentaCorriente.cliente.id.persona.apellido || ''}</td>
            <td>{cuentaCorriente.cliente.id.persona.dni}</td>
            <td>${cuentaCorriente.debe}</td>
            <td>${cuentaCorriente.haber}</td>
            <td>${cuentaCorriente.montoMaximo}</td>
            <td>{cuentaCorriente.cantidadCuotasMaximas}</td>
            <td>{isDateNull(cuentaCorriente.fechaUltimoPago)}</td>
            <td>{isDateNull(cuentaCorriente.fechaProximoPago)}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverCuentaCorriente(cuentaCorriente)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemCuentaCorrienteEliminada)
