import { GET_ROLES_MENUS } from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    menus: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ROLES_MENUS:
            return {
                ...state,
                menus: action.payload,
            }
        default:
            return state
    }
}
