export const calculoIvas = (items) => {
    const ivas = []
    let neto0 = 0.0,
        neto10 = 0.0,
        neto21 = 0.0
    let iva0 = 0.0,
        iva10 = 0.0,
        iva21 = 0.0
    let impIva = 0.0
    let impNeto = 0.0

    items.forEach((item) => {
        if (item.departamento === undefined) {
            switch (Number(item.articulo.familia.departamento.tasaIva)) {
                case 0:
                    neto0 += Number(item.precio)
                    iva0 += 0
                    break
                case 10.5:
                    neto10 += Number(item.precio) / 1.105
                    iva10 += Number(item.precio) - Number(item.precio) / 1.105
                    break
                case 21:
                    neto21 += Number(item.precio) / 1.21
                    iva21 += Number(item.precio) - Number(item.precio) / 1.21
                    break
                default:
                    break
            }
        } else
            switch (Number(item.departamento.tasaIva)) {
                case 0:
                    neto0 += Number(item.precio)
                    iva0 += 0
                    break
                case 10.5:
                    neto10 += Number(item.precio) / 1.105
                    iva10 += item.precio - item.precio / 1.105
                    break
                case 21:
                    neto21 += Number(item.precio) / 1.21
                    iva21 += Number(item.precio) - Number(item.precio) / 1.21
                    break
                default:
                    break
            }
        //Id iva --- 3: 0% --  4: 10.5% -- 5: 21% -- 6: 27% -- 8: 5% -- 9: 2.5%
        if (neto0 !== 0) {
            ivas.push({
                id: 3,
                Base: Math.round(neto0, 2),
                importe: Math.round(iva0, 2),
            })
        }
        if (neto10 !== 0) {
            ivas.push({
                id: 4,
                Base: Math.round(neto10, 2),
                importe: Math.round(iva10, 2),
            })
        }
        if (neto21 !== 0) {
            ivas.push({
                id: 5,
                Base: Math.round(neto21, 2),
                importe: Math.round(iva21, 2),
            })
        }
        impIva =
            Math.round(iva21, 2) + Math.round(iva10, 2) + Math.round(iva0, 2)
        impNeto =
            Math.round(neto21, 2) + Math.round(neto10, 2) + Math.round(neto0, 2)
    })

    return {
        ivas,
        impIva,
        impNeto,
        iva0,
        iva10,
        iva21,
    }
}

export const determinarPrecio = (precio, tasaIva, tipoComprobante) => {
    if (tipoComprobante !== 1) return precio
    switch (tasaIva) {
        case 0:
            // iva0 += 0;
            return precio
        case 10.5:
            // iva10 += precio - (precio / 1.105);
            return Math.Round(precio / 1.105, 2)
        case 21:
            // iva21 += precio - (precio / 1.21);
            return Math.Round(precio / 1.21)
        default:
            return 0
    }
}

export const generateQRUrl = (
    fecha,
    cuit,
    ptoVta,
    tipoCmp,
    nroCmp,
    importe,
    moneda,
    tipoDocRec,
    nroDocRec,
    codAut
) => {
    const WS_QR_URL = 'https://www.afip.gob.ar/fe/qr/?p='
    const codigoQR = {
        ver: '1',
        fecha,
        cuit,
        ptoVta,
        tipoCmp,
        nroCmp,
        importe,
        moneda,
        ctz: '1',
        tipoDocRec,
        nroDocRec,
        tipoCodAut: 'E',
        codAut,
    }
    return `${WS_QR_URL}${btoa(codigoQR)}`
}

export const getConcepto = (concepto) => {
    switch (concepto) {
        case 1:
            return 'PRODUCTOS'
        case 2:
            return 'SERVICIOS'
        case 3:
            return 'PRODUCTOS_SERVICIOS'
        default:
            return 'PRODUCTOS'
    }
}
