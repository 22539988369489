import {
    GET_ALL_STOCK_GENERAL,
    GET_STOCK_GENERAL,
    ADD_STOCK_GENERAL,
    EDIT_STOCK_GENERAL,
    DELETE_STOCK_GENERAL,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    stockGenerales: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_STOCK_GENERAL:
            return {
                ...state,
                stockGenerales: action.payload,
            }
        case GET_STOCK_GENERAL:
            return {
                ...state,
                stockGeneral: action.payload,
            }
        case ADD_STOCK_GENERAL:
            return {
                ...state,
                stockGenerales: [...state.stockGenerales, action.payload],
            }
        case EDIT_STOCK_GENERAL:
            return {
                ...state,
                stockGenerales: state.stockGenerales.map((stockGeneral) =>
                    stockGeneral.id === action.payload.id
                        ? (stockGeneral = action.payload)
                        : stockGeneral
                ),
            }
        case DELETE_STOCK_GENERAL:
            return {
                ...state,
                stockGenerales: state.stockGenerales.filter(
                    (stockGeneral) =>
                        stockGeneral.id.articulo.idArticulo !== action.payload
                ),
            }
        default:
            return state
    }
}
