import React, { Fragment, useEffect, useState } from 'react'

// css
import './Informes.css'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faFileImport,
    faCalendarAlt,
    faBuilding,
    faMoneyBillAlt,
    faFileExcel,
    faUser,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons'

// charts
import { Doughnut, Bar } from '@reactchartjs/react-chart.js'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import ItemInforme from './ItemInforme'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// services
import { getAllSucursales } from '../../Services/sucursalService'
import {
    getArticulosMasVendidos,
    getVentaTotalByMediosPagos,
    getReporteVentasByArticulo,
    getReporteVentasTotal,
} from '../../Services/informesService'
import { getExcelReporteVenta } from '../../Services/reporteService'
import { getAllClientes } from '../../Services/clienteService'
import { getAllEmpleados } from '../../Services/empleadoService'

// alert swal
import Swal from 'sweetalert2'

const Informes = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const FormatDate = (date) => {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        return year + '-' + month + '-' + day
    }

    const [isLoad, setIsLoad] = useState(true)
    const [sucursales, setSucursales] = useState([])
    const [sucursal, setSucursal] = useState(token.sucursal.id)
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState()
    const [empleados, setEmpleados] = useState([])
    const [empleado, setEmpleado] = useState()
    const [fechaDesde, setFechaDesde] = useState(FormatDate(new Date()))
    const [fechaHasta, setFechaHasta] = useState(FormatDate(new Date()))
    const [articulos, setArticulos] = useState([])
    const [ventaMedioPago, setVentaMedioPago] = useState([])
    const [dataVenta, setDataVenta] = useState({})
    const [dataArticulo, setDataArticulo] = useState({})
    const [facturada, setFacturada] = useState(false)

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        if (isLoad) {
            getAllSucursales(true, 0, '', 1000)
                .then((res) => {
                    setSucursales(res.data.content)
                })
                .catch((err) => {
                    console.log(err)
                })
            getAllClientes(true, 0, '', 1000)
                .then((res) => {
                    setClientes(res.data.content)
                })
                .catch((err) => console.log(err))
            getAllEmpleados(true, 0, '', 1000)
                .then((res) => {
                    setEmpleados(res.data.content)
                })
                .catch((err) => console.log(err))

            getTotalVenta()
            TopTenArticulos()
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    useEffect(() => {
        getAllEmpleados(true, 0, '', 1000)
            .then((res) => {
                setEmpleados(res.data.content)
            })
            .catch((err) => console.log(err))
        setEmpleado('')
    }, [sucursal])

    const TopTenArticulos = () => {
        getArticulosMasVendidos(
            fechaDesde,
            fechaHasta,
            sucursal,
            cliente,
            empleado,
            facturada
        )
            .then((res) => {
                setArticulos(res.data)
                let labels = res.data.map((item) => item.articulo.nombre)
                let data = res.data.map((item) =>
                    Number.parseFloat(item.cantidad / 1000).toFixed(2)
                )
                setDataArticulo({
                    labels,
                    datasets: [
                        {
                            label: 'Articulos mas vendidos',
                            data,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                })
            })
            .catch((err) => {
                Swal.fire({
                    toast: true,
                    position: 'top',
                    title: 'No se registraron ventas.',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }
    const getTotalVenta = async () => {
        try {
            const ventaMedioPago = await getVentaTotalByMediosPagos(
                fechaDesde,
                fechaHasta,
                sucursal,
                cliente,
                empleado,
                facturada
            )
            let total = 0
            ventaMedioPago.data.forEach(
                (element) => (total = total + element.monto)
            )
            setVentaMedioPago([
                ...ventaMedioPago.data,
                {
                    medioPago: {
                        idMedioPago: '*',
                        nombre: 'TOTAL',
                    },
                    monto: Number.parseFloat(total).toFixed(2),
                },
            ])
            let labels = ventaMedioPago.data.map(
                (item) => item.medioPago.nombre
            )
            let data = ventaMedioPago.data.map((item) =>
                Number.parseFloat(item.monto).toFixed(2)
            )
            setDataVenta({
                labels,
                datasets: [
                    {
                        label: '#Total Venta',
                        data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            })
        } catch (error) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No se registraron ventas.',
                type: 'info',
                showConfirmButton: false,
                timer: 1500,
            })
        }
    }

    const handleReporteVentaExcel = async () => {
        let ventasArticulos, ventasTotales, ventaMedioPago

        try {
            ventasArticulos = (
                await getReporteVentasByArticulo(
                    fechaDesde,
                    fechaHasta,
                    sucursal,
                    cliente,
                    empleado,
                    facturada
                )
            ).data
            ventasTotales = (
                await getReporteVentasTotal(
                    fechaDesde,
                    fechaHasta,
                    sucursal,
                    cliente,
                    empleado,
                    facturada
                )
            ).data
            ventaMedioPago = (
                await getVentaTotalByMediosPagos(
                    fechaDesde,
                    fechaHasta,
                    sucursal,
                    cliente,
                    empleado,
                    facturada
                )
            ).data

            const excel = await getExcelReporteVenta(
                ventasArticulos,
                ventasTotales,
                ventaMedioPago,
                null
            )

            const contenido = encodeURI(excel.data)
            let excelWindow = window.open('')
            excelWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contenido}'>
                </iframe>`
            )
            setTimeout(() => {
                excelWindow.close()
            }, 3000)
        } catch (error) {
            Swal.fire({
                title: 'No hay datos para estas fechas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faFileImport} title={'Informes Ventas'} />
                </div>

                <div className="bg-gris p-3">
                    <div className="d-flex bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputFechaDesde">Desde *</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="inputFechaDesde"
                                    name="fechaDesde"
                                    value={fechaDesde}
                                    onChange={(e) =>
                                        setFechaDesde(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputFechaHasta">Hasta *</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="inputFechaHasta"
                                    value={fechaHasta}
                                    name="fechaHasta"
                                    onChange={(e) =>
                                        setFechaHasta(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputSucursal">Sucursal *</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faBuilding} />
                                </span>
                                <select
                                    id="inputSucursal"
                                    className="custom-select"
                                    name="sucursal"
                                    value={sucursal}
                                    onChange={(e) =>
                                        setSucursal(e.target.value)
                                    }
                                >
                                    {sucursales.map((sucursal, index) => (
                                        <option key={index} value={sucursal.id}>
                                            {sucursal.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputEmpleado">Empleado</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faUserTie} />
                                </span>
                                <select
                                    id="inputEmpleado"
                                    className="custom-select"
                                    name="empleado"
                                    value={empleado}
                                    onChange={(e) =>
                                        setEmpleado(e.target.value)
                                    }
                                >
                                    <option
                                        key={null}
                                        value={undefined}
                                    ></option>
                                    {empleados.map((empleado, index) => (
                                        <option
                                            key={index}
                                            value={empleado.id.persona.id}
                                        >
                                            {empleado.id.persona.nombre
                                                ? empleado.id.persona.nombre
                                                : ''}{' '}
                                            {empleado.id.persona.apellido
                                                ? empleado.id.persona.apellido
                                                : ''}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputCliente">Cliente</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                                <select
                                    id="inputCliente"
                                    className="custom-select"
                                    name="cliente"
                                    value={cliente}
                                    onChange={(e) => setCliente(e.target.value)}
                                >
                                    <option
                                        key={null}
                                        value={undefined}
                                    ></option>
                                    {clientes.map((cliente, index) => (
                                        <option
                                            key={index}
                                            value={cliente.id.persona.id}
                                        >
                                            {cliente.id.persona.nombre +
                                                ' ' +
                                                cliente.id.persona.apellido}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                            <label htmlFor="checkboxFacturada" className="mr-2">
                                Facturada
                            </label>
                            <input
                                type="checkbox"
                                id="checkboxFacturada"
                                name="facturada"
                                checked={facturada}
                                onChange={(e) => setFacturada(e.target.checked)}
                            />
                        </div>

                        <div
                            className="ms-auto p-2 bd-highlight"
                            style={{ marginTop: 20 }}
                        >
                            <button
                                className="btn btn-outline-success btn-lg"
                                onClick={() => {
                                    handleReporteVentaExcel()
                                }}
                            >
                                <FontAwesomeIcon icon={faFileExcel} /> Generar
                                Reporte
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <div className="bg-gris p-3 justify-content-center">
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-info btn-lg"
                            onClick={() => {
                                getTotalVenta()
                            }}
                        >
                            <FontAwesomeIcon icon={faMoneyBillAlt} /> Total
                            Venta
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Medio de Pago</th>
                                        <th scope="col">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ventaMedioPago.map((mpago) => (
                                        <ItemInforme
                                            key={parseInt(mpago.medioPago.id)}
                                            data={mpago}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-6">
                            <Bar data={dataVenta} />
                        </div>
                    </div>
                </div>
                <br />
                <div className="bg-gris p-3">
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-success btn-lg"
                            onClick={() => {
                                TopTenArticulos()
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Top 10 Articulos
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Codigo</th>
                                        <th scope="col">Articulo</th>
                                        <th scope="col">Pesable</th>
                                        <th scope="col">Cantidad Vendida</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articulos.map((articulo) => (
                                        <ItemInforme
                                            key={parseInt(articulo.articulo.id)}
                                            data={articulo}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-6">
                            <Doughnut data={dataArticulo} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(Informes)
