import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

import ModalDetallePresupuesto from './ModalDetallePresupuesto/ModalDetallePresupuesto'
// import ModalEnviarPresupuesto from './ModalEnviarPresupuesto/ModalEnviarPresupuesto';

//services
import { getPdfPresupuesto } from './../../Services/reporteService'
import { deletePresupuesto } from '../../Services/presupuestoService'

// Sweet alert
import Swal from 'sweetalert2'

const ItemPresupuesto = ({ presupuesto, setIsLoad }) => {
    const handleImprimir = async (presupuesto) => {
        try {
            const pdf = await getPdfPresupuesto(presupuesto)
            const contenido = encodeURI(pdf)
            let pdfWindow = window.open('')
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64,${contenido}'>
                </iframe>`
            )
        } catch (error) {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hubo un error vuelve a intentar!',
            })
        }
    }

    const deletePresupuestoSubmit = (presupuesto) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara el presupuesto de la sucursal!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                deletePresupuesto(presupuesto.id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'El presupuesto fue eliminado de la sucursal.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            title:
                                error.response.data.message ||
                                'Error al eliminar el presupuesto a la sucursal.',
                            type: 'error',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{presupuesto.id}</th>
            <td>${presupuesto.total.toFixed(2)}</td>
            <td>{presupuesto.createdAt.split('T')[0]}</td>
            <td>{presupuesto.fechaVencimiento.split('T')[0]}</td>
            <td>{presupuesto.sucursal.nombre}</td>
            <td>
                {presupuesto.cliente.id.persona.apellido},{' '}
                {presupuesto.cliente.id.persona.nombre}
            </td>
            <td>
                <ModalDetallePresupuesto item={presupuesto} />
                <button
                    className="btn text-info"
                    onClick={() => handleImprimir(presupuesto)}
                >
                    <FontAwesomeIcon icon={faPrint} /> Imprimir
                </button>
                <button
                    className="btn text-primary"
                    title="Eliminar"
                    onClick={() => {
                        deletePresupuestoSubmit(presupuesto)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemPresupuesto)
