import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// modals
import ModalDetalleVenta from './ModalDetalleVenta/ModalDetalleVenta'
import ModalDetalleMedioPago from './ModalDetalleMedioPago/ModalDetalleMedioPago'

// services
import { deleteByIdVentas } from './../../Services/ventaService'

// moment
import moment from 'moment'
import 'moment-timezone'

// alert swal
import Swal from 'sweetalert2'

const ItemVenta = ({ venta, setIsLoad }) => {
    const deleteVenta = (venta) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara el articulo de la sucursal!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                deleteByIdVentas(venta.id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'La venta fue eliminada de la sucursal.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>
        if (estado === 'PAGADA_PARCIALMENTE')
            return <p className="text-warning">PAGADA PARCIALMENTE</p>
        if (estado === 'ADEUDADA')
            return <p className="text-primary">ADEUDADA</p>
        if (estado === 'CANCELADA')
            return <p className="text-warning">CANCELADA</p>
    }

    const fechaHora = moment(venta.createdAt)
        .tz('America/Argentina/Buenos_Aires')
        .format()

    return (
        <tr>
            <th scope="row">{venta.numeroComprobante}</th>
            <td>${venta.total.toFixed(2)}</td>
            <td>{estado(venta.estado)}</td>
            <td>
                {fechaHora.split('T')[0]} /{' '}
                {fechaHora.split('T')[1].replace('-03:00', '')}
            </td>
            <td>{venta.sucursal.nombre}</td>
            <td>{venta.tipoComprobante.nombre}</td>
            <td>
                {venta.cliente.id.persona.apellido},{' '}
                {venta.cliente.id.persona.nombre}
            </td>
            <td>
                <ModalDetalleMedioPago item={venta} />
                <ModalDetalleVenta item={venta} />
                <button
                    className="btn text-primary"
                    title="Eliminar"
                    onClick={() => {
                        deleteVenta(venta)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemVenta)
