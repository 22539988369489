import axios from 'axios'
import { url } from './global'

export const getAllVentas = async (
    activo,
    page,
    sucursal,
    nombre,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/ventas?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}&sucursal=${sucursal}`,
        { headers }
    )
    return res
}

export const getByIdVentas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/ventas/${id}`, { headers })
    return res
}

export const editByIdVentas = async (venta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/ventas/${venta.idVenta}`, venta, {
        headers,
    })
    return res
}

export const deleteByIdVentas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/ventas/${id}`, { headers })
    return res
}

export const recoverByIdVentas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/ventas/${id}/recover`,
        {},
        { headers }
    )
    return res
}

export const saveVentas = async (venta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/ventas`, venta, { headers })
    return res
}

export const getLastNroComprobante = async () => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(`${url}/v2/ventas/last`, { headers })
    return res
}
