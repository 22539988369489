import React, { Fragment } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLayerGroup,
    faPlus,
    faPercent,
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

// services
import { saveDepartamentos } from '../../Services/departamentoService'
import { saveError } from '../../Services/errorService'

import Swal from 'sweetalert2'
import Header from '../Header/Header'

function AddDepartamento({ history }) {
    const formik = useFormik({
        initialValues: {
            codigo: '',
            nombre: '',
            tasaIva: 0.0,
        },
        validationSchema: Yup.object({
            codigo: Yup.number().required('El codigo es requerido').min(1),
            nombre: Yup.string().required('El nombre es requerido'),
            tasaIva: Yup.number()
                .required('El iva es requerido')
                .min(0, 'El iva debe ser mayor a cero'),
        }),
        onSubmit: (values) => addDepartamento(values),
    })

    const addDepartamento = (values) => {
        // creamos el departamento
        let departamento = {
            ...values,
        }

        saveDepartamentos(departamento)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear un departamento',
                    detalle: error.response.data.message,
                    entidad: 'DEPARTAMENTO',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    title: 'Faltan datos o algunos son incorrectos.',
                    text: error.response.data.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faLayerGroup} title={'Departamentos'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCodigo">Codigo</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="number"
                                        id="inputCodigo"
                                        step="any"
                                        name="codigo"
                                        className="form-control"
                                        placeholder="1"
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.codigo &&
                                formik.errors.codigo ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.codigo}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faLayerGroup} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputIva">Tasa IVA</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="number"
                                        id="inputIva"
                                        step="any"
                                        name="tasaIva"
                                        className="form-control"
                                        placeholder="Ej. %21.0"
                                        value={formik.values.tasaIva}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.tasaIva &&
                                formik.errors.tasaIva ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.tasaIva}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear
                                Departamento
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddDepartamento)
