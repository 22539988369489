import {
    GET_FAMILIAS,
    GET_FAMILIA,
    ADD_FAMILIA,
    DELETE_FAMILIA,
    EDIT_FAMILIA,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    familias: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAMILIAS:
            return {
                ...state,
                familias: action.payload,
            }
        case GET_FAMILIA:
            return {
                ...state,
                familia: action.payload,
            }
        case ADD_FAMILIA:
            return {
                ...state,
                familias: [...state.familias, action.payload],
            }
        case EDIT_FAMILIA:
            return {
                ...state,
                familias: state.familias.map((familia) =>
                    familia.idFamilia === action.payload.idFamilia
                        ? (familia = action.payload)
                        : familia
                ),
            }
        case DELETE_FAMILIA:
            return {
                ...state,
                familias: state.familias.filter(
                    (familia) => familia.idFamilia !== action.payload
                ),
            }
        default:
            return state
    }
}
