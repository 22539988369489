import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverOfertaDeleted } from '../../Services/ofertaService'

// Sweet alert
import Swal from 'sweetalert2'

// router
import { withRouter } from 'react-router-dom'

const ItemOfertaEliminada = ({ oferta, setIsLoad }) => {
    const recoverOfertaSubmit = (oferta) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverOfertaDeleted(oferta.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'La oferta fue recuperada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{oferta.id}</th>
            <td>{oferta.nombre}</td>
            <td>{oferta.articulo.nombre}</td>
            <td>{oferta.fechaFinOferta.split('T')[0]}</td>
            <td>
                <b>%</b> {oferta.porcentajeDescuento}
            </td>
            <td>
                <b>$</b> {oferta.precioFinal}
            </td>
            <td>{oferta.promocionCantidad}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverOfertaSubmit(oferta)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemOfertaEliminada)
