import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverDepartamentoDeleted } from '../../Services/departamentoService'

// Sweet alert
import Swal from 'sweetalert2'

// router
import { withRouter } from 'react-router-dom'

const ItemDepartamentoEliminado = ({ departamento, setIsLoad }) => {
    const recoverDepartamentoSubmit = (departamento) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverDepartamentoDeleted(departamento.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'El departamento fue recuperado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{departamento.codigo}</th>
            <td>{departamento.nombre}</td>
            <td>{departamento.tasaIva}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverDepartamentoSubmit(departamento)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemDepartamentoEliminado)
