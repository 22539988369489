import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { deleteByIdEmpleado } from '../../Services/empleadoService'

const ItemEmpleado = ({ empleado, history, setIsLoad }) => {
    const EliminarEmpleadoSubmit = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Un empleado eliminado no se puede recuperar!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                empleado.activo = false
                deleteByIdEmpleado(id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'El empleado fue eliminado.',
                            'success'
                        )
                        // INVOCAMOS EL ACTION
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                    })
            }
        })
    }

    return (
        <tr key={parseInt(empleado.id.persona.id)}>
            <th scope="row">{empleado.id.persona.id}</th>
            <td>{empleado.id.persona.nombre}</td>
            <td>{empleado.id.persona.apellido}</td>
            <td>{empleado.id.persona.dni}</td>
            <td>{empleado.id.persona.correo}</td>
            <td>{empleado.id.persona.telefono}</td>
            {/* <td>{empleado.cargo.nombre}</td> */}
            <td>
                <button
                    className="btn text-danger"
                    onClick={() => {
                        history.push(`/edit-empleado/${empleado.id}`, {
                            empleado: empleado,
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button
                    className="btn text-primary"
                    onClick={() => {
                        EliminarEmpleadoSubmit(empleado.id.persona.id)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemEmpleado)
