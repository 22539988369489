import {
    GET_TARJETAS,
    GET_TARJETA,
    EDIT_TARJETA,
    ADD_TARJETA,
    DELETE_TARJETA,
} from './Types'

export const GetTarjetas = (res) => {
    return {
        type: GET_TARJETAS,
        payload: res.data,
    }
}

export const AddTarjeta = (res) => {
    return {
        type: ADD_TARJETA,
        payload: res.data,
    }
}

export const EditaTarjeta = (res) => {
    return {
        type: EDIT_TARJETA,
        payload: res.data,
    }
}

export const GetTarjeta = (res) => {
    return {
        type: GET_TARJETA,
        payload: res.data,
    }
}

export const DeleteTarjeta = (id) => {
    return {
        type: DELETE_TARJETA,
        payload: id,
    }
}
