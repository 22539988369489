import axios from 'axios'
import { url } from './global'

export const getHistorialStockByArticulo = async (stockSucursal, id) => {}
export const getAllStockSucursalPage = async (stockSucursal, id) => {}

export const saveStockSucursal = async (stockSucursal) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.post(`${url}/v2/stocks`, stockSucursal, { headers })
    return res
}

export const getAllStockSucursal = async (
    activo,
    page,
    sucursal,
    lista,
    codigoBarra,
    nombre,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/stocks?${nombre ? `nombre=${nombre}&` : ''}${lista ? `lista=${lista}&` : ''}${codigoBarra ? `codigoBarra=${codigoBarra}&` : ''}activo=${activo}&page=${page}&size=${size}&sucursal=${sucursal}`,
        { headers }
    )
    return res
}

export const getStockSucursalById = async (sucursalId, articuloId, listaId) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/stocks/sucursal/${sucursalId}/articulo/${articuloId}?${listaId ? `lista=${listaId}&` : ''}`,
        { headers }
    )
    return res
}

export const getStockSucursalByCodigo = async (sucursalId, codigo, listaId) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/stocks/sucursal/${sucursalId}/articulo/1?${listaId ? `lista=${listaId}&` : ''}&codigo=${codigo}`,
        { headers }
    )
    return res
}

export const editByIdStockSucursal = async (sucursalId, articuloId, values) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/stocks/sucursal/${sucursalId}/articulo/${articuloId}`,
        values,
        { headers }
    )
    return res
}

export const deleteStockSucursal = async (idSucursal, idArticulo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(
        `${url}/v2/stocks/sucursal/${idSucursal}/articulo/${idArticulo}`,
        { headers }
    )
    return res
}

export const recoverStockSucursal = async (idSucursal, idArticulo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/stocks/sucursal/${idSucursal}/articulo/${idArticulo}/recover`,
        {},
        { headers }
    )
    return res
}
