import React, { Fragment } from 'react'

// routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import bootstrap
import '../Assets/css/bootstrap.min.css'

// redux
import { Provider } from 'react-redux'
import store from '../Store/Store'

// Rutas Privadas
//import PrivateRoute from './Utiles/PrivateRoute';

// components
import Home from './Home/Home'

import ConsolaVenta from './ConsolaVenta/Ventas'

import Articulos from './Articulo/Articulos'
import ArticulosEliminados from './Articulo/ArticulosEliminados'
import AddArticulo from './Articulo/AddArticulo'
import AddArticuloCompuestos from './Articulo/AddArticuloCompuesto'
import AddArticuloImportar from './Articulo/AddArticuloImportar'
import EditArticulo from './Articulo/EditArticulo'

import Departamentos from './Departamento/Departamentos'
import AddDepartamento from './Departamento/AddDepartamento'
import EditDepartamento from './Departamento/EditDepartamento'
import DepartamentosEliminados from './Departamento/DepartamentosEliminados'

import Familias from './Familia/Familias'
import AddFamilia from './Familia/AddFamilia'
import EditFamilia from './Familia/EditFamilia'
import FamiliaEliminada from './Familia/FamiliaEliminada'

import Marcas from './Marca/Marcas'
import AddMarca from './Marca/AddMarca'
import EditMarca from './Marca/EditMarca'
import MarcasEliminadas from './Marca/MarcasEliminadas'

import TipoComprobantes from './TipoComprobante/TipoComprobantes'
import AddTipoComprobante from './TipoComprobante/AddTipoComprobante'
import EditarTipoComprobante from './TipoComprobante/EditarTipoComprobante'

import Tarjetas from './Tarjeta/Tarjetas'
import AddTarjeta from './Tarjeta/AddTarjeta'
import EditTarjeta from './Tarjeta/EditTarjeta'
import TarjetasEliminadas from './Tarjeta/TarjetasEliminadas'

import ListasPrecios from './ListaPrecio/ListasPrecios'
import AddListaPrecio from './ListaPrecio/AddListaPrecio'
import EditListaPrecio from './ListaPrecio/EditListaPrecio'

import Ofertas from './Oferta/Ofertas'
import AddOferta from './Oferta/AddOferta'
import EditOferta from './Oferta/EditOferta'
import OfertasEliminadas from './Oferta/OfertasEliminadas'

import Clientes from './Cliente/Clientes'
import AddCliente from './Cliente/AddCliente'
import EditCliente from './Cliente/EditCliente'
import ClientesEliminados from './Cliente/ClientesEliminados'

import Cargos from './Cargo/Cargos'
import AddCargo from './Cargo/AddCargo'
import EditCargo from './Cargo/EditCargo'

import Sucursales from './Sucursal/Sucursales'
import AddSucursal from './Sucursal/AddSucursal'
import EditSucursal from './Sucursal/EditSucursal'

import Empleados from './Empleado/Empleados'
import AddEmpleado from './Empleado/AddEmpleado'
import EditEmpleado from './Empleado/EditEmpleado'
import EmpleadosEliminados from './Empleado/EmpleadosEliminados'

import CuentasCorrientes from './CuentaCorrienteCliente/CuentasCorrientes'
import AddCuentaCorriente from './CuentaCorrienteCliente/AddCuentaCorriente'
import EditCuentaCorriente from './CuentaCorrienteCliente/EditCuentaCorriente'
import CuentasCorrientesClienteEliminada from './CuentaCorrienteCliente/CuentasCorrientesEliminadas'
import IngresoPagoCliente from './CuentaCorrienteCliente/IngresoPago'
import CuentasCorrientesInformeVentaAdeudada from './CuentaCorrienteCliente/InformeVentaAdeudada'

import CuentasCorrientesProveedor from './CuentaCorrienteProveedor/CuentasCorrientes'
import AddCuentaCorrienteProveedor from './CuentaCorrienteProveedor/AddCuentaCorriente'
import EditCuentaCorrienteProveedor from './CuentaCorrienteProveedor/EditCuentaCorriente'
import CuentasCorrientesProveedorEliminada from './CuentaCorrienteProveedor/CuentasCorrientesEliminada'
import IngresoPago from './CuentaCorrienteProveedor/IngresoPago'

// import AddPedidoSucursal from './PedidoSucursal/AddPedidoSucursal';
// import PedidosSucursal from './PedidoSucursal/PedidosSucursal';

import StockSucursal from './StockSucursal/StockSucursal'
import EditStockSucursal from './StockSucursal/EditStockSucursal'
import AddStockSucursal from './StockSucursal/AddStockSucursal'
import StockSucursalEliminado from './StockSucursal/StockSucursalEliminado'

import Usuario from './Usuario/Usuario'
import AddUsuario from './Usuario/AddUsuario'
import EditUsuario from './Usuario/EditUsuario'
import UsuariosEliminados from './Usuario/UsuariosEliminados'

import Login from './Login/Login'

import Proveedores from './Proveedor/Proveedores'
import AddProveedor from './Proveedor/AddProveedor'
import EditProveedor from './Proveedor/EditProveedor'
import ProveedoresEliminados from './Proveedor/ProveedoresEliminados'

import Compras from './Compra/Compras'
import AddCompra from './Compra/AddCompra'
import InformeCompras from './Compra/InformeCompras'
import ComprasEliminadas from './Compra/ComprasEliminadas'

import Cajas from './Caja/Cajas'
import OpenCaja from './Caja/OpenCaja'
import EditCaja from './Caja/EditCaja'
import InformeCajas from './Caja/InformeCajas'
import CajasEliminadas from './Caja/CajasEliminadas'

import Pdf from './Utiles/pdf'
import TicketVenta from './Utiles/TicketVenta'

import CorreoElectronico from './CorreoElectronico/CorreoElectronico'

import Presupuestos from './Presupuestos/Presupuestos'
import PresupuestosEliminado from './Presupuestos/PresupuestosEliminado'

import Informes from './Ventas/Informes'
import Ventas from './Ventas/Venta'
import VentaEliminada from './Ventas/VentaEliminada'

import ListasPreciosEliminados from './ListaPrecio/ListasPreciosEliminados'
import SucursalesEliminados from './Sucursal/SucursalesEliminados'

import PuntoVenta from './PuntoVenta/PuntoVenta'
import AddPuntoVenta from './PuntoVenta/AddPuntoVenta'
import EditPuntoVenta from './PuntoVenta/EditPuntoVenta'
import PuntoVentaEliminadas from './PuntoVenta/PuntoVentaEliminadas'

import DatoFiscal from './DatoFiscal/DatoFiscal'
import AddDatoFiscal from './DatoFiscal/AddDatoFiscal'
import EditDatoFiscal from './DatoFiscal/EditDatoFiscal'
import DatoFiscalEliminado from './DatoFiscal/DatoFiscalEliminado'

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Fragment>
                    <Switch>
                        <Route exact path="/" component={Login} />

                        <Route exact path="/home" component={Home} />

                        <Route exact path="/vender" component={ConsolaVenta} />

                        <Route exact path="/articulos" component={Articulos} />
                        <Route
                            exact
                            path="/add-articulo"
                            component={AddArticulo}
                        />
                        <Route
                            exact
                            path="/add-articulo-compuesto"
                            component={AddArticuloCompuestos}
                        />
                        <Route
                            exact
                            path="/add-articulo-importar"
                            component={AddArticuloImportar}
                        />
                        <Route
                            exact
                            path="/edit-articulo/:id"
                            component={EditArticulo}
                        />
                        <Route
                            exact
                            path="/articulos-eliminados"
                            component={ArticulosEliminados}
                        />

                        <Route
                            exact
                            path="/departamentos"
                            component={Departamentos}
                        />
                        <Route
                            exact
                            path="/add-departamento"
                            component={AddDepartamento}
                        />
                        <Route
                            exact
                            path="/edit-departamento/:id"
                            component={EditDepartamento}
                        />
                        <Route
                            exact
                            path="/departamentos-eliminados"
                            component={DepartamentosEliminados}
                        />

                        <Route exact path="/familias" component={Familias} />
                        <Route
                            exact
                            path="/add-familia"
                            component={AddFamilia}
                        />
                        <Route
                            exact
                            path="/edit-familia/:id"
                            component={EditFamilia}
                        />
                        <Route
                            exact
                            path="/familia-eliminada"
                            component={FamiliaEliminada}
                        />

                        <Route exact path="/marcas" component={Marcas} />
                        <Route exact path="/add-marca" component={AddMarca} />
                        <Route
                            exact
                            path="/edit-marca/:id"
                            component={EditMarca}
                        />
                        <Route
                            exact
                            path="/marcas-eliminadas"
                            component={MarcasEliminadas}
                        />

                        <Route
                            exact
                            path="/comprobantes"
                            component={TipoComprobantes}
                        />
                        <Route
                            exact
                            path="/add-comprobante"
                            component={AddTipoComprobante}
                        />
                        <Route
                            exact
                            path="/edit-comprobante/:id"
                            component={EditarTipoComprobante}
                        />
                        <Route
                            exact
                            path="/compras-eliminadas"
                            component={ComprasEliminadas}
                        />

                        <Route exact path="/tarjetas" component={Tarjetas} />
                        <Route
                            exact
                            path="/add-tarjeta"
                            component={AddTarjeta}
                        />
                        <Route
                            exact
                            path="/edit-tarjeta/:id"
                            component={EditTarjeta}
                        />
                        <Route
                            exact
                            path="/tarjetas-eliminadas"
                            component={TarjetasEliminadas}
                        />

                        <Route
                            exact
                            path="/listas-precios"
                            component={ListasPrecios}
                        />
                        <Route
                            exact
                            path="/add-lista-precio"
                            component={AddListaPrecio}
                        />
                        <Route
                            exact
                            path="/edit-lista-precio/:id"
                            component={EditListaPrecio}
                        />
                        <Route
                            exact
                            path="/lista-precio-eliminada"
                            component={ListasPreciosEliminados}
                        />

                        <Route exact path="/ofertas" component={Ofertas} />
                        <Route exact path="/add-oferta" component={AddOferta} />
                        <Route
                            exact
                            path="/edit-oferta/:id"
                            component={EditOferta}
                        />
                        <Route
                            exact
                            path="/ofertas-eliminadas"
                            component={OfertasEliminadas}
                        />

                        <Route exact path="/clientes" component={Clientes} />
                        <Route
                            exact
                            path="/add-cliente"
                            component={AddCliente}
                        />
                        <Route
                            exact
                            path="/edit-cliente/:id"
                            component={EditCliente}
                        />
                        <Route
                            exact
                            path="/clientes-eliminados"
                            component={ClientesEliminados}
                        />

                        <Route exact path="/cargos" component={Cargos} />
                        <Route exact path="/add-cargo" component={AddCargo} />
                        <Route
                            exact
                            path="/edit-cargo/:id"
                            component={EditCargo}
                        />

                        <Route
                            exact
                            path="/sucursales"
                            component={Sucursales}
                        />
                        <Route
                            exact
                            path="/add-sucursal"
                            component={AddSucursal}
                        />
                        <Route
                            exact
                            path="/edit-sucursal/:id"
                            component={EditSucursal}
                        />
                        <Route
                            exact
                            path="/sucursales-eliminadas"
                            component={SucursalesEliminados}
                        />

                        <Route exact path="/empleados" component={Empleados} />
                        <Route
                            exact
                            path="/add-empleado"
                            component={AddEmpleado}
                        />
                        <Route
                            exact
                            path="/edit-empleado/:id"
                            component={EditEmpleado}
                        />
                        <Route
                            exact
                            path="/empleados-eliminados"
                            component={EmpleadosEliminados}
                        />

                        <Route
                            exact
                            path="/cuentas-corrientes-clientes"
                            component={CuentasCorrientes}
                        />
                        <Route
                            exact
                            path="/add-cuenta-corriente-clientes"
                            component={AddCuentaCorriente}
                        />
                        <Route
                            exact
                            path="/edit-cuenta-corriente-clientes/:id"
                            component={EditCuentaCorriente}
                        />
                        <Route
                            exact
                            path="/cuentas-corrientes-cliente-eliminada"
                            component={CuentasCorrientesClienteEliminada}
                        />
                        <Route
                            exact
                            path="/cuentas-corrientes-informes-venta-adeudada"
                            component={CuentasCorrientesInformeVentaAdeudada}
                        />
                        <Route
                            exact
                            path="/ingreso-pago-cliente"
                            component={IngresoPagoCliente}
                        />

                        <Route
                            exact
                            path="/cuentas-corrientes-proveedores"
                            component={CuentasCorrientesProveedor}
                        />
                        <Route
                            exact
                            path="/add-cuenta-corriente-proveedores"
                            component={AddCuentaCorrienteProveedor}
                        />
                        <Route
                            exact
                            path="/edit-cuenta-corriente-proveedores/:id"
                            component={EditCuentaCorrienteProveedor}
                        />
                        <Route
                            exact
                            path="/cuentas-corrientes-proveedores-eliminada"
                            component={CuentasCorrientesProveedorEliminada}
                        />
                        <Route
                            exact
                            path="/ingreso-pago-proveedor"
                            component={IngresoPago}
                        />

                        {/* <Route exact path='/pedidos' component={PedidosSucursal} />
            <Route exact path='/add-pedido-sucursal' component={AddPedidoSucursal} /> */}

                        <Route
                            exact
                            path="/stock-sucursal"
                            component={StockSucursal}
                        />
                        <Route
                            exact
                            path="/edit-stock-sucursal/:id"
                            component={EditStockSucursal}
                        />
                        <Route
                            exact
                            path="/add-stock-sucursal"
                            component={AddStockSucursal}
                        />
                        <Route
                            exact
                            path="/delete-stock-sucursal"
                            component={StockSucursalEliminado}
                        />

                        <Route exact path="/usuarios" component={Usuario} />
                        <Route
                            exact
                            path="/add-usuario"
                            component={AddUsuario}
                        />
                        <Route
                            exact
                            path="/edit-usuario/:id"
                            component={EditUsuario}
                        />
                        <Route
                            exact
                            path="/usuarios-eliminados"
                            component={UsuariosEliminados}
                        />

                        <Route
                            exact
                            path="/proveedores"
                            component={Proveedores}
                        />
                        <Route
                            exact
                            path="/add-proveedor"
                            component={AddProveedor}
                        />
                        <Route
                            exact
                            path="/edit-proveedor/:id"
                            component={EditProveedor}
                        />
                        <Route
                            exact
                            path="/proveedores-eliminados"
                            component={ProveedoresEliminados}
                        />

                        <Route exact path="/compras" component={Compras} />
                        <Route
                            exact
                            path="/add-compras"
                            component={AddCompra}
                        />
                        <Route
                            exact
                            path="/compras-informes"
                            component={InformeCompras}
                        />

                        <Route exact path="/cajas" component={Cajas} />
                        <Route
                            exact
                            path="/cajas-eliminadas"
                            component={CajasEliminadas}
                        />
                        <Route
                            exact
                            path="/apertura-caja"
                            component={OpenCaja}
                        />
                        <Route exact path="/edit-caja" component={EditCaja} />
                        <Route
                            exact
                            path="/cajas-informes"
                            component={InformeCajas}
                        />

                        <Route exact path="/pdf" component={Pdf} />
                        <Route
                            exacy
                            path="/ticket-venta"
                            component={TicketVenta}
                        />

                        <Route
                            exacy
                            path="/correo-electronico"
                            component={CorreoElectronico}
                        />

                        <Route
                            exacy
                            path="/presupuestos"
                            component={Presupuestos}
                        />
                        <Route
                            exacy
                            path="/presupuestos-eliminados"
                            component={PresupuestosEliminado}
                        />

                        <Route exacy path="/informes" component={Informes} />
                        <Route exact path="/ventas" component={Ventas} />
                        <Route
                            exact
                            path="/ventas-eliminadas"
                            component={VentaEliminada}
                        />

                        <Route
                            exacy
                            path="/punto-ventas"
                            component={PuntoVenta}
                        />
                        <Route
                            exacy
                            path="/add-punto-venta"
                            component={AddPuntoVenta}
                        />
                        <Route
                            exacy
                            path="/edit-punto-venta"
                            component={EditPuntoVenta}
                        />
                        <Route
                            exacy
                            path="/punto-ventas-eliminadas"
                            component={PuntoVentaEliminadas}
                        />

                        <Route
                            exacy
                            path="/dato-fiscal"
                            component={DatoFiscal}
                        />
                        <Route
                            exacy
                            path="/add-dato-fiscal"
                            component={AddDatoFiscal}
                        />
                        <Route
                            exacy
                            path="/edit-dato-fiscal"
                            component={EditDatoFiscal}
                        />
                        <Route
                            exacy
                            path="/dato-fiscal-eliminados"
                            component={DatoFiscalEliminado}
                        />
                    </Switch>
                </Fragment>
            </Router>
        </Provider>
    )
}

export default App
