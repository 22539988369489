import React, { Fragment, useEffect, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileInvoiceDollar,
    faSearch,
    faBuilding,
} from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import ItemVentaEliminada from './ItemVentaEliminada'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// services
import { getAllVentas } from '../../Services/ventaService'
import { getAllSucursales } from '../../Services/sucursalService'

// alert swal
import Swal from 'sweetalert2'

const VentaEliminada = ({ history, location }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const [isLoad, setIsLoad] = useState(true)
    const [ventas, setVentas] = useState([])
    const [sucursales, setSucursales] = useState([])
    const [sucursal, setSucursal] = useState(
        location.state.sucursal || token.sucursal
    )

    // pagination
    const [page, setPage] = useState(0)
    const [totalRegister, setTotalRegister] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()

        getAllVentas(false, page, sucursal.id, query)
            .then((res) => {
                setVentas(res.data.content)
                setTotalRegister(res.data.totalElements)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))

        getAllSucursales(true, 0, '', 1000)
            .then((res) => setSucursales(res.data.content))
            .catch((err) => console.log(err))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad, sucursal])

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllVentas(false, page + 1, sucursal.id, query)
            .then((res) => {
                setVentas(res.data.content)
                setTotalRegister(res.data.totalElements)
                setPage(page + 1)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllVentas(false, page - 1, sucursal.id, query)
            .then((res) => {
                setVentas(res.data.content)
                setTotalRegister(res.data.totalElements)
                setPage(page - 1)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const handleBuscador = (e) => {
        setQuery(e.target.value)
        if (e.key === 'Enter') {
            // verificar si la query isEmpty hacer otra consulta

            getAllVentas(false, 0, sucursal.id, e.target.value)
                .then((res) => {
                    setPage(0)
                    setVentas(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faFileInvoiceDollar}
                        title={'Ventas Eliminadas'}
                    />
                </div>

                <div className="d-flex bg-gris p-3 align-items-center justify-content-between">
                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">
                            <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                                type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => {
                                    handleBuscador(e)
                                }}
                            />
                        </div>
                    </div>
                    {rol === 'ROLE_ADMIN_MAGENTA' ? (
                        <div className="p-2 bd-highlight">
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-info btn-lg dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <FontAwesomeIcon icon={faBuilding} />{' '}
                                    {`${sucursal.nombre}`}
                                </button>
                                <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    {sucursales.map((sucursal) => {
                                        return (
                                            <button
                                                key={sucursal.id}
                                                className="dropdown-item"
                                                onClick={() =>
                                                    setSucursal(sucursal)
                                                }
                                            >
                                                {sucursal.nombre}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="bg-gris p-3">
                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-outline-info"
                                onClick={() => previousPage()}
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual:{' '}
                            {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button
                                className="btn btn-outline-info"
                                onClick={() => nextPage()}
                                aria-label="Next"
                            >
                                <span aria-hidden="true">
                                    Siguiente &raquo;
                                </span>
                            </button>
                        </div>
                    </div>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">Nro Comprobante</th>
                                <th scope="col">Total</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Fecha - Hora</th>
                                <th scope="col">Sucursal</th>
                                <th scope="col">Comprobante</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ventas.map((venta) => (
                                <ItemVentaEliminada
                                    key={parseInt(venta.id)}
                                    venta={venta}
                                    setIsLoad={setIsLoad}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(VentaEliminada)
