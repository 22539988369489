import axios from 'axios'
import { url } from './global'

export const getAllFamilias = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/familias?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdFamilias = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/familias/${id}`, { headers })
    return res
}

export const editByIdFamilias = async (familia) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/v2/familias/${familia.id}`, familia, {
        headers,
    })
    return res
}

export const deleteByIdFamilias = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/familias/${id}`, { headers })
    return res
}

export const saveFamilias = async (familia) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/familias`, familia, { headers })
    return res
}
export const recoverFamiliaDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/familias/${id}/recover`,
        {},
        { headers }
    )
    return res
}
