import axios from 'axios'
import { url } from './global'

export const getAllPuntoVentas = async (
    sucursal,
    activo,
    page,
    nombre,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/punto-ventas?${sucursal ? `sucursal=${sucursal}&` : ''}${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdPuntoVentas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/punto-ventas/${id}`, { headers })
    return res
}

export const editByIdPuntoVentas = async (puntoVenta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/punto-ventas/${puntoVenta.id}`,
        puntoVenta,
        { headers }
    )
    return res
}

export const deleteByIdPuntoVentas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/punto-ventas/${id}`, { headers })
    return res
}

export const savePuntoVentas = async (puntoVenta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/punto-ventas`, puntoVenta, {
        headers,
    })
    return res
}

export const recoverPuntoVentasDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/punto-ventas/${id}/recover`,
        {},
        { headers }
    )
    return res
}
