// types Articulos
export const GET_ARTICULOS = 'MOSTRAR_ARTICULOS'
export const GET_ARTICULO = 'GET_ARTICULO'
export const ADD_ARTICULO = 'ADD_ARTICULO'
export const EDIT_ARTICULO = 'EDIT_ARTICULO'
export const DELETE_ARTICULO = 'DELETE_ARTICULO'

// types Departamentos
export const MOSTRAR_DEPARTAMENTOS = 'MOSTRAR_DEPARTAMENTOS'
export const ADD_DEPARTAMENTO = 'ADD_DEPARTAMENTO'
export const EDIT_DEPARTAMENTO = 'EDIT_DEPARTAMENTO'
export const GET_DEPARTAMENTO = 'GET_DEPARTAMENTO'
export const DELETE_DEPARTAMENTO = 'DELETE_DEPARTAMENTO'

// tyoes Familias
export const GET_FAMILIAS = 'GET_FAMILIAS'
export const ADD_FAMILIA = 'ADD_FAMILIA'
export const EDIT_FAMILIA = 'EDIT_FAMILIA'
export const GET_FAMILIA = 'GET_FAMILIA'
export const DELETE_FAMILIA = 'DELETE_FAMILIA'

// type Marcas
export const GET_MARCAS = 'GET_MARCAS'
export const ADD_MARCA = 'ADD_MARCA'
export const EDIT_MARCA = 'EDIT_MARCA'
export const GET_MARCA = 'GET_MARCA'
export const DELETE_MARCA = 'DELETE_MARCA'

// type Unidades de Medidas
export const GET_UMEDIDAS = 'GET_UMEDIDAS'
export const ADD_UMEDIDA = 'ADD_UMEDIDA'
export const EDIT_UMEDIDA = 'EDIT_UMEDIDA'
export const GET_UMEDIDA = 'GET_UMEDIDA'
export const DELETE_UMEDIDA = 'DELETE_UMEDIDA'

// type Tipo Comprobantes
export const GET_COMPROBANTES = 'GET_COMPROBANTES'
export const ADD_COMPROBANTE = 'ADD_COMPROBANTE'
export const EDIT_COMPROBANTE = 'EDIT_COMPROBANTE'
export const GET_COMPROBANTE = 'GET_COMPROBANTE'
export const DELETE_COMPROBANTE = 'DELETE_COMPROBANTE'

// type Tarjetas
export const GET_TARJETAS = 'GET_TARJETAS'
export const ADD_TARJETA = 'ADD_TARJETA'
export const EDIT_TARJETA = 'EDIT_TARJETA'
export const GET_TARJETA = 'GET_TARJETA'
export const DELETE_TARJETA = 'DELETE_TARJETA'

// type LPrecio
export const GET_LISTAS_PRECIOS = 'GET_LISTAS_PRECIOS'
export const ADD_LISTA_PRECIO = 'ADD_LISTA_PRECIO'
export const EDIT_LISTA_PRECIO = 'EDIT_LISTA_PRECIO'
export const GET_LISTA_PRECIO = 'GET_LISTA_PRECIO'
export const DELETE_LISTA_PRECIO = 'DELETE_LISTA_PRECIO'

// type Oferta
export const GET_OFERTAS = 'GET_OFERTAS'
export const ADD_OFERTA = 'ADD_OFERTA'
export const EDIT_OFERTA = 'EDIT_OFERTA'
export const GET_OFERTA = 'GET_OFERTA'
export const DELETE_OFERTA = 'DELETE_OFERTA'

// type Personas
export const GET_PERSONAS = 'GET_PERSONAS'
export const ADD_PERSONA = 'ADD_PERSONA'
export const EDIT_PERSONA = 'EDIT_PERSONA'
export const GET_PERSONA = 'GET_PERSONA'
export const DELETE_PERSONA = 'DELETE_PERSONA'

// type Clientes
export const GET_CLIENTES = 'GET_CLIENTES'
export const ADD_CLIENTE = 'ADD_CLIENTE'
export const EDIT_CLIENTE = 'EDIT_CLIENTE'
export const GET_CLIENTE = 'GET_CLIENTE'
export const DELETE_CLIENTE = 'DELETE_CLIENTE'

// type Empleados
export const GET_EMPLEADOS = 'GET_EMPLEADOS'
export const ADD_EMPLEADO = 'ADD_EMPLEADO'
export const EDIT_EMPLEADO = 'EDIT_EMPLEADO'
export const GET_EMPLEADO = 'GET_EMPLEADO'
export const DELETE_EMPLEADO = 'DELETE_EMPLEADO'

// type Cargos
export const GET_CARGOS = 'GET_CARGOS'
export const ADD_CARGO = 'ADD_CARGO'
export const EDIT_CARGO = 'EDIT_CARGO'
export const GET_CARGO = 'GET_CARGO'
export const DELETE_CARGO = 'DELETE_CARGO'

// type Sucursales
export const GET_SUCURSALES = 'GET_SUCURSALES'
export const ADD_SUCURSAL = 'ADD_SUCURSAL'
export const EDIT_SUCURSAL = 'EDIT_SUCURSAL'
export const GET_SUCURSAL = 'GET_SUCURSAL'
export const DELETE_SUCURSAL = 'DELETE_SUCURSAL'

// type Cuentas Corrientes
export const GET_CUENTAS_CORRIENTES = 'GET_CUENTAS_CORRIENTES'
export const ADD_CUENTA_CORRIENTE = 'ADD_CUENTA_CORRIENTE'
export const EDIT_CUENTA_CORRIENTE = 'EDIT_CUENTA_CORRIENTE'
export const GET_CUENTA_CORRIENTE = 'GET_CUENTA_CORRIENTE'
export const DELETE_CUENTA_CORRIENTE = 'DELETE_CUENTA_CORRIENTE'

// type Medios Pagos
export const GET_MEDIOS_PAGOS = 'GET_MEDIOS_PAGOS'
export const ADD_MEDIO_PAGO = 'ADD_MEDIO_PAGO'
export const EDIT_MEDIO_PAGO = 'EDIT_MEDIO_PAGO'
export const GET_MEDIO_PAGO = 'GET_MEDIO_PAGO'
export const DELETE_MEDIO_PAGO = 'DELETE_MEDIO_PAGO'

// types Ventas
export const GET_VENTAS = 'GET_VENTAS'
export const GET_VENTA = 'GET_VENTA'
export const ADD_VENTA = 'ADD_VENTA'
export const EDIT_VENTA = 'EDIT_VENTA'
export const DELETE_VENTA = 'DELETE_VENTA'

// types Pedidos
export const GET_PEDIDOS = 'GET_PEDIDOS'
export const GET_PEDIDO = 'GET_PEDIDO'
export const ADD_PEDIDO = 'ADD_PEDIDO'
export const EDIT_PEDIDO = 'EDIT_PEDIDO'
export const DELETE_PEDIDO = 'DELETE_PEDIDO'

// types Stock general
export const GET_ALL_STOCK_GENERAL = 'GET_ALL_STOCK_GENERAL'
export const GET_STOCK_GENERAL = 'GET_STOCK_GENERAL'
export const ADD_STOCK_GENERAL = 'ADD_STOCK_GENERAL'
export const EDIT_STOCK_GENERAL = 'EDIT_STOCK_GENERAL'
export const DELETE_STOCK_GENERAL = 'DELETE_STOCK_GENERAL'

// types Stock sucursal
export const GET_ALL_STOCK_SUCURSAL = 'GET_ALL_STOCK_SUCURSAL'
export const GET_STOCK_SUCURSAL = 'GET_STOCK_SUCURSAL'
export const ADD_STOCK_SUCURSAL = 'ADD_STOCK_SUCURSAL'
export const EDIT_STOCK_SUCURSAL = 'EDIT_STOCK_SUCURSAL'
export const DELETE_STOCK_SUCURSAL = 'DELETE_STOCK_SUCURSAL'

// types PROVEEDORES
export const GET_PROVEEDORES = 'GET_PROVEEDORES'
export const GET_PROVEEDOR = 'GET_PROVEEDOR'
export const ADD_PROVEEDOR = 'ADD_PROVEEDOR'
export const EDIT_PROVEEDOR = 'EDIT_PROVEEDOR'
export const DELETE_PROVEEDOR = 'DELETE_PROVEEDOR'

// type Roles-Menus
export const GET_ROLES_MENUS = 'GET_ROLES_MENUS'

// type CONDICION FISCAL
export const GET_CONDICIONES_FISCALES = 'GET_CONDICIONES_FISCALES'

// type Roles
export const GET_ROLES = 'GET_ROLES'

export const ADD_TOKEN = 'ADD_TOKEN'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
