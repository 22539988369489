import axios from 'axios'
import { url } from './global'

// GET VENTAS BY CLIENTE (CUENTA CORRIENTE, ADEUDADAS Y PAGAS_PARCIALMENTE)
export const getAllVentasByCliente = async (cliente) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/ventas/estado?cliente=${cliente}&estadoVentaFirst=1&estadoVentaSecond=2`,
        { headers }
    )
    return res
}

// GET PAGOS BY VENTA
export const getPagosByVenta = async (venta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(
        `${url}/v2/cuenta-corriente/clientes/venta/${venta}/pagos`,
        { headers }
    )
    return res
}

// SAVE PAGOS
export const savePago = async (pago) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(
        `${url}/v2/cuenta-corriente/clientes/venta/pagos`,
        pago,
        { headers }
    )
    return res
}
