import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

//functions
import { determinarTipoComprobante } from '../Helpers'

// router
import { withRouter } from 'react-router-dom'

const ItemClienteModalVenta = ({
    cliente,
    setCliente,
    setListaPrecio,
    setTipoComprobante,
}) => {
    const handleCliente = (cliente) => {
        setCliente(cliente)
        setListaPrecio(cliente.listaPrecio.id)
        determinarTipoComprobante(
            cliente.condicionFiscal.id,
            setTipoComprobante
        )
    }

    return (
        <tr>
            <th scope="row">{cliente.id.persona.id}</th>
            <td>
                {cliente.id.persona.nombre + ', ' + cliente.id.persona.apellido}
            </td>
            <td>{cliente.id.persona.dni}</td>
            <td>{cliente.condicionFiscal.nombre}</td>
            <td>
                <button
                    className="btn text-danger"
                    data-dismiss="modal"
                    onClick={() => {
                        handleCliente(cliente)
                    }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemClienteModalVenta)
