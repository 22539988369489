import React from 'react'

import { withRouter } from 'react-router-dom'

const TicketVenta = ({ history }) => {
    const imprimir = () => {
        window.print()
        history.goBack()
    }

    return (
        <div class="ticket">
            <img
                src="https://yt3.ggpht.com/-3BKTe8YFlbA/AAAAAAAAAAI/AAAAAAAAAAA/ad0jqQ4IkGE/s900-c-k-no-mo-rj-c0xffffff/photo.jpg"
                alt="Logotipo"
            />
            <p class="centrado">
                Parzibyte's blog
                <br />
                New New York
                <br />
                23/08/2017 08:22 a.m.
            </p>
            <table>
                <thead>
                    <tr>
                        <th class="cantidad">CANT</th>
                        <th class="producto">PRODUCTO</th>
                        <th class="precio">$$</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="cantidad">1.00</td>
                        <td class="producto">CHEETOS VERDES 80 G</td>
                        <td class="precio">$8.50</td>
                    </tr>
                    <tr>
                        <td class="cantidad">2.00</td>
                        <td class="producto">KINDER DELICE</td>
                        <td class="precio">$10.00</td>
                    </tr>
                    <tr>
                        <td class="cantidad">1.00</td>
                        <td class="producto">COCA COLA 600 ML</td>
                        <td class="precio">$10.00</td>
                    </tr>
                    <tr>
                        <td class="cantidad"></td>
                        <td class="producto">TOTAL</td>
                        <td class="precio">$28.50</td>
                    </tr>
                </tbody>
            </table>
            <p class="centrado">
                ¡GRACIAS POR SU COMPRA!
                <br />
                parzibyte.me
            </p>
            <button
                class="oculto-impresion"
                onClick={() => {
                    imprimir()
                }}
            >
                Imprimir
            </button>
        </div>
    )
}

export default withRouter(TicketVenta)
