import React, { Fragment, Component } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faKeyboard,
    faSitemap,
} from '@fortawesome/free-solid-svg-icons'

// router
import { connect } from 'react-redux'

// actions
import { EditarCargo, GetCargo } from '../../Actions/CargoAction'

// services
import { getByIdCargo, editByIdCargo } from '../../Services/cargoService'

class EditCargo extends Component {
    state = {
        nombre: '',
        codigo: '',
        error: false,
    }

    componentDidMount() {
        const { id } = this.props.match.params

        getByIdCargo(id)
            .then((res) => {
                this.props.getCargo(res)
                const { nombre, codigo, activo } = this.props.cargo
                this.setState({ nombre, codigo, activo })
            })
            .catch((err) => console.log(err))
    }

    handleCargo = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        })
    }

    editarCargoSubmit = (e) => {
        e.preventDefault()

        // destructuring
        const { nombre, codigo } = this.state
        const { id } = this.props.match.params

        // validations
        if (nombre === '' || codigo === '') {
            this.setState({ error: true })
            return
        }

        // creo el departamento actualizado
        const cargoUpdated = {
            idCargo: parseInt(id, 10),
            nombre,
            codigo,
        }

        editByIdCargo(cargoUpdated)
            .then((res) => {
                this.props.editarCargo(res)
                this.setState({ error: false, nombre: '', codigo: '' })
                this.props.history.goBack()
            })
            .catch((error) => {
                console.log(error.response)
                this.setState({ error: true })
            })
    }

    render() {
        const { nombre, codigo } = this.state

        return (
            <Fragment>
                <div className="container-fluid mt-3">
                    <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                        <BotonBack />
                        <Subtitulo icon={faSitemap} title={'Cargos'} />
                    </div>

                    <div className="bg-gris p-4">
                        <form onSubmit={this.editarCargoSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">Nombre</label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon icon={faSitemap} />
                                        </span>
                                        <input
                                            type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Gerente, Cocinero, Cajero..."
                                            defaultValue={nombre}
                                            onChange={this.handleCargo}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputCodigo">Codigo</label>
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon
                                                icon={faKeyboard}
                                            />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputCodigo"
                                            name="codigo"
                                            placeholder="Ej. 1, 2, 100"
                                            defaultValue={codigo}
                                            onChange={this.handleCargo}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-outline-danger"
                                >
                                    <FontAwesomeIcon icon={faEdit} /> Editar
                                    Cargo
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    cargo: state.cargos.cargo,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getCargo: (id) => dispatch(GetCargo(id)),
        editarCargo: (cargo) => dispatch(EditarCargo(cargo)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCargo)
