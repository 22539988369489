import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMoneyBill, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { deleteByIdCuentasCorriente } from '../../Services/cuentaCorrienteClienteService'

const ItemCuentaCorriente = ({ cuentaCorriente, history, setIsLoad }) => {
    const isDateNull = (fecha) => {
        if (fecha === null) return '-'
        else {
            let fechaFinal = fecha.split('T')[0]
            return fechaFinal
        }
    }

    const eliminarCuentaCorrienteSubmit = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: 'Se eliminara la cuenta corriente del cliente!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdCuentasCorriente(id)
                    .then((res) => {
                        Swal.fire(
                            'Eliminado!',
                            'La cuenta corriente fue eliminada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr key={parseInt(cuentaCorriente.id)}>
            <th scope="row">{cuentaCorriente.id}</th>
            <td>{cuentaCorriente.cliente.id.persona.nombre}</td>
            <td>{cuentaCorriente.cliente.id.persona.apellido || ''}</td>
            <td>{cuentaCorriente.cliente.id.persona.dni}</td>
            <td>${cuentaCorriente.debe}</td>
            <td>${cuentaCorriente.haber}</td>
            <td>${cuentaCorriente.montoMaximo}</td>
            <td>{cuentaCorriente.cantidadCuotasMaximas}</td>
            <td>{isDateNull(cuentaCorriente.fechaUltimoPago)}</td>
            <td>{isDateNull(cuentaCorriente.fechaProximoPago)}</td>
            <td>
                <button
                    className="btn text-success"
                    onClick={() => {
                        history.push(`/ingreso-pago-cliente`, {
                            cuentaCorriente: cuentaCorriente,
                        })
                    }}
                >
                    <FontAwesomeIcon icon={faMoneyBill} /> Pagos
                </button>
                <button
                    className="btn text-danger"
                    onClick={() => {
                        history.push(
                            `/edit-cuenta-corriente-clientes/${cuentaCorriente.id}`,
                            { cuentaCorriente: cuentaCorriente }
                        )
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button
                    className="btn text-primary"
                    onClick={() =>
                        eliminarCuentaCorrienteSubmit(cuentaCorriente.id)
                    }
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemCuentaCorriente)
