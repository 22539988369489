import {
    GET_COMPROBANTES,
    GET_COMPROBANTE,
    ADD_COMPROBANTE,
    EDIT_COMPROBANTE,
    DELETE_COMPROBANTE,
} from './Types'

export const GetComprobantes = (res) => {
    return {
        type: GET_COMPROBANTES,
        payload: res.data,
    }
}

export const AddComprobante = (res) => {
    return {
        type: ADD_COMPROBANTE,
        payload: res.data,
    }
}

export const EditarComprobante = (res) => {
    return {
        type: EDIT_COMPROBANTE,
        payload: res.data,
    }
}

export const GetComprobante = (res) => {
    return {
        type: GET_COMPROBANTE,
        payload: res.data,
    }
}

export const DeleteComprobante = (id) => {
    return {
        type: DELETE_COMPROBANTE,
        payload: id,
    }
}
