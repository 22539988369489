import React, { Fragment, useState, useEffect } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faUserCircle,
    faUserEdit,
    faAt,
    faMobileAlt,
    faIdCard,
    faMapMarkedAlt,
    faBirthdayCake,
    faIdCardAlt,
    faPercent,
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// services
import { saveCliente } from '../../Services/clienteService'
import { getAllCondicionesFiscales } from '../../Services/condicionFiscal'
import { getAllListasPrecios } from '../../Services/listaPrecioService'
import { saveError } from '../../Services/errorService'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

import Swal from 'sweetalert2'
import Header from '../Header/Header'

function AddClientes({ history }) {
    const [isLoad, setIsLoad] = useState(true)

    const [condicionesFiscales, setCondicionesFiscales] = useState([])

    const [listasPrecios, setListasPrecios] = useState([])

    useEffect(() => {
        if (isLoad) {
            getAllCondicionesFiscales()
                .then((res) => {
                    setCondicionesFiscales(res.data)
                })
                .catch((error) => console.log(error.response))

            getAllListasPrecios(true, 0, '', 1000)
                .then((res) => {
                    setListasPrecios(res.data.content)
                })
                .catch((error) => console.log(error.response))
            setIsLoad(false)
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            correo: '',
            telefono: '',
            dni: '',
            cuilCuit: '',
            direccion: '',
            fechaNacimiento: '',
            descuento: 0,
            condicionFiscal: '',
            listaPrecio: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Obligatorio.'),
            apellido: Yup.string(),
            correo: Yup.string().email('Formato invalido.'),
            telefono: Yup.number(),
            dni: Yup.number().required('Obligatorio.'),
            cuilCuit: Yup.string(),
            direccion: Yup.string(),
            fechaNacimiento: Yup.date(),
            descuento: Yup.number()
                .required('Obligatorio.')
                .min(0, 'El número debe ser positivo.')
                .max(100, 'El número no puede ser mayor a 100.'),
            condicionFiscal: Yup.object().required('Obligatorio'),
            listaPrecio: Yup.object().required('Obligatorio'),
        }),
        onSubmit: (values) => addClienteSubmit(values),
    })

    const addClienteSubmit = (values) => {
        let cliente = {
            ...values,
            listaPrecioId: values.listaPrecio.id,
            condicionFiscalId: values.condicionFiscal.id,
        }

        saveCliente(cliente)
            .then((res) => {
                Swal.fire({
                    position: 'top',
                    toast: true,
                    title: 'Cliente creado.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                })
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje:
                        'Error al intentar crear el cliente, verifique los datos',
                    detalle: error.message,
                    entidad: 'CLIENTE',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    title:
                        error.response.data.message ||
                        'No se pudo crear el cliente',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faUserCircle} title={'Clientes'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre *</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Juan"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserEdit} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputApellido"
                                        name="apellido"
                                        placeholder="Ej. Martinez"
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.errors.apellido ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.apellido}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCorreo">
                                    Correo Electronico
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faAt} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputCorreo"
                                        name="correo"
                                        className="form-control"
                                        placeholder="Ej. juan@ejemplo.com"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.errors.correo ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.correo}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Ej. 3814123456"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.errors.telefono ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.telefono}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputDni">DNI *</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDni"
                                        name="dni"
                                        className="form-control"
                                        placeholder="Ej. 10123456"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.dni && formik.errors.dni ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.dni}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuilCuit">CUIL/CUIT</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faIdCardAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCuilCuit"
                                        name="cuilCuit"
                                        placeholder="Ej. 20101234560"
                                        value={formik.values.cuilCuit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">
                                    Direccion
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                        />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Pje. La Paz 123"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputFechaNacimiento">
                                    Fecha Nacimiento
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faBirthdayCake}
                                        />
                                    </span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="inputFechaNacimiento"
                                        name="fechaNacimiento"
                                        value={formik.values.fechaNacimiento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.errors.fechaNacimiento ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.fechaNacimiento}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputDescuento">
                                    Descuento *
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDescuento"
                                        name="descuento"
                                        className="form-control"
                                        placeholder="10"
                                        value={formik.values.descuento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.descuento &&
                                formik.errors.descuento ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.descuento}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCondicionFiscal">
                                    Condicion fiscal *
                                </label>
                                <div>
                                    <Select
                                        options={condicionesFiscales}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'condicionFiscal',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.condicionFiscal &&
                                formik.errors.condicionFiscal ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.condicionFiscal}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputListaPrecio">
                                    Lista de precio *
                                </label>
                                <div>
                                    <Select
                                        options={listasPrecios}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'listaPrecio',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) =>
                                            values.idListaPrecio
                                        }
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.listaPrecio &&
                                formik.errors.listaPrecio ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.listaPrecio}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Cliente
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddClientes)
