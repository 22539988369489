import React, { Fragment } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

import ItemDetalleCompra from './ItemDetalleCompra'

const ModalDetalleCompra = ({ item }) => {
    const estado = (estado) => {
        if (estado === 'ADEUDADA')
            return <span className="text-primary">ADEUDADA</span>
        if (estado === 'PAGADA_PARCIALMENTE')
            return <span className="text-warning">PAGADA PARCIALMENTE</span>
        if (estado === 'PAGADA')
            return <span className="text-success">PAGADA</span>
    }

    return (
        <Fragment>
            <button
                type="button"
                className="btn text-info"
                data-toggle="modal"
                data-target={'#modalCompras' + item.id}
            >
                <FontAwesomeIcon icon={faList} /> Detalle
            </button>

            <div
                className="modal fade"
                id={'modalCompras' + item.id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalComprasTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalComprasTitle">
                                <b>Detalle Compra</b>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <span className="col-md">
                                    <b>Fecha:</b>
                                </span>
                                <span className="col-md">
                                    <b>Total:</b>
                                </span>
                                <span className="col-md">
                                    <b>Estado:</b>
                                </span>
                            </div>

                            <div className="row">
                                <span className="col-md">
                                    {item.createdAt.split('T')[0]}
                                </span>
                                <span className="col-md">
                                    ${item.total.toFixed(2)}
                                </span>
                                <span className="col-md">
                                    {estado(item.estado)}
                                </span>
                            </div>

                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Articulo</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.items.map((item, index) => (
                                        <ItemDetalleCompra
                                            key={index}
                                            item={item}
                                            index={index}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ModalDetalleCompra)
