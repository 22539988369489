import axios from 'axios'
import { url } from './global'

export const getAllTarjetas = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/tarjetas?${
            nombre ? `nombre=${nombre}&` : ''
        }activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdTarjetas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/tarjetas/${id}`, { headers })
    return res
}

export const editByIdTarjetas = async (tarjeta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/v2/tarjetas/${tarjeta.id}`, tarjeta, {
        headers,
    })
    return res
}

export const deleteByIdTarjetas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/tarjetas/${id}`, { headers })
    return res
}

export const saveTarjetas = async (tarjeta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/tarjetas`, tarjeta, { headers })
    return res
}

export const recoverTarjetaDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/tarjetas/${id}/recover`,
        {},
        { headers }
    )
    return res
}
