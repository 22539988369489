import React, { Fragment, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCashRegister,
    faCopyright,
    faEdit,
} from '@fortawesome/free-solid-svg-icons'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

// services
import { saveError } from '../../Services/errorService'
import { getAllCondicionesFiscales } from '../../Services/condicionFiscal'
import { editByIdDatoFiscal } from '../../Services/datoFiscalService'

import Swal from 'sweetalert2'

function EditDatoFiscal({ history, location }) {
    const datoFiscal = location.state.datoFiscal

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const [condicionesFiscales, setCondicionesFiscales] = useState([])
    const [isLoad, setIsLoad] = useState(true)

    const formik = useFormik({
        initialValues: {
            razonSocial: datoFiscal.razonSocial,
            domicilioFiscal: datoFiscal.domicilioFiscal,
            condicionFiscal: datoFiscal.condicionFiscal,
            cuitCuil: datoFiscal.cuitCuil,
            apiKey: datoFiscal.apiKey,
            puntoVenta: datoFiscal.puntoVenta,
        },
        validationSchema: Yup.object({
            razonSocial: Yup.string().required('La razon social es requerida'),
            domicilioFiscal: Yup.string(),
            condicionFiscal: Yup.object().required(
                'La condicion fiscal es requerida'
            ),
            cuitCuil: Yup.string().required('El CUIT/CUIL es requerido'),
            apiKey: Yup.string(),
            puntoVenta: Yup.number().required(
                'El punto de venta afip es requerido'
            ),
        }),
        onSubmit: (values) => editDatoFiscal(values),
    })

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        document.getElementById('inputRazonSocial').focus()
        if (isLoad) {
            getAllCondicionesFiscales()
                .then((res) => setCondicionesFiscales(res.data))
                .catch((err) => console.log('error'))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editDatoFiscal = (values) => {
        // creo el punto de vento actualizado
        let datoFiscalEdit = {
            ...values,
            condicionFiscalId: values.condicionFiscal.id,
            id: datoFiscal.id,
        }

        editByIdDatoFiscal(datoFiscalEdit)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar editar el dato fiscal',
                    detalle: error.message,
                    entidad: 'DATO_FISCAL',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al editar el dato fiscal, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.log(error.response.data)
            })
    }
    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCashRegister}
                        title={'Punto de Ventas'}
                    />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputRazonSocial">
                                    Razon Social
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputRazonSocial"
                                        name="razonSocial"
                                        className="form-control"
                                        placeholder="Ej. MAGENTA SRL"
                                        value={formik.values.razonSocial}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.razonSocial &&
                                formik.errors.razonSocial ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.razonSocial}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputDomicilioFiscal">
                                    Domicilio Fiscal
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDomicilioFiscal"
                                        name="domicilioFiscal"
                                        className="form-control"
                                        placeholder="Ej. Av Mate de Luna 3000"
                                        value={formik.values.domicilioFiscal}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.domicilioFiscal &&
                                formik.errors.domicilioFiscal ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.domicilioFiscal}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCuitCuil">
                                    CUIT / CUIL
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputCuitCuil"
                                        name="cuitCuil"
                                        className="form-control"
                                        placeholder="2012345661"
                                        value={formik.values.cuitCuil}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cuitCuil &&
                                formik.errors.cuitCuil ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.cuitCuil}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCondicionFiscal">
                                    Conficion Fiscal
                                </label>
                                <div>
                                    <Select
                                        placeholder={
                                            'Seleccionar una condicion fiscal'
                                        }
                                        options={condicionesFiscales}
                                        defaultValue={
                                            formik.values.condicionFiscal
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'condicionFiscal',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.conficionFiscal &&
                                formik.errors.conficionFiscal ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.conficionFiscal}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputApiKey">Api Key</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputApiKey"
                                        name="apiKey"
                                        className="form-control"
                                        placeholder="api key afip"
                                        value={formik.values.apiKey}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPuntoVenta">
                                    Punto Venta Afip
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="number"
                                        id="inputPuntoVenta"
                                        name="puntoVenta"
                                        className="form-control"
                                        placeholder="punto venta afip"
                                        value={formik.values.puntoVenta}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.puntoVenta &&
                                formik.errors.puntoVenta ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.puntoVenta}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-danger"
                            >
                                <FontAwesomeIcon icon={faEdit} /> Editar Dato
                                Fiscal
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditDatoFiscal)
