import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverClienteDeleted } from '../../Services/clienteService'

// Sweet alert
import Swal from 'sweetalert2'

// router
import { withRouter } from 'react-router-dom'

const ItemClienteEliminado = ({ cliente, setIsLoad }) => {
    const recoverClienteSubmit = (cliente) => {
        Swal.fire({
            title: 'Estas seguro de recuperarla?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverClienteDeleted(cliente.id.persona.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'El cliente fue recuperado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{cliente.id.persona.id}</th>
            <td>{cliente.id.persona.nombre}</td>
            <td>{cliente.id.persona.apellido}</td>
            <td>{cliente.id.persona.dni}</td>
            <td>{cliente.id.persona.cuilCuit}</td>
            <td>{cliente.condicionFiscal.nombre}</td>
            <td>{cliente.listaPrecio.nombre}</td>
            <td>{cliente.descuento}%</td>
            <td>{cliente.id.persona.correo}</td>
            <td>{cliente.id.persona.telefono}</td>
            <td>{cliente.puntaje}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverClienteSubmit(cliente)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemClienteEliminado)
