import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// Service
import { recoverStockSucursal } from '../../Services/stockSucursalService'

// routes
import { withRouter } from 'react-router-dom'

// Sweer Alert
import Swal from 'sweetalert2'

const ItemStockSucursalEliminado = ({
    articulo,
    sucursal,
    setIsLoad,
    history,
}) => {
    const condicionStock = (stock) => {
        if (parseFloat(stock) >= parseFloat(articulo.cantidad))
            return <p className="text-success">{articulo.cantidad}</p>
        else
            return (
                <p className="text-warning">
                    {Number.parseFloat(articulo.cantidad).toFixed(2)}
                </p>
            )
    }

    const recoverStock = (articulo) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                recoverStockSucursal(sucursal.id, articulo.articulo.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'El articulo de la sucursal fue recuperado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{articulo.articulo.id}</th>
            <td>{articulo.articulo.nombre}</td>
            <td>{Number(articulo.articulo.costo).toFixed(2)}</td>
            <td>{Number(articulo.articulo.precioFinal).toFixed(2)}</td>
            <td>{Number(articulo.articulo.precioFinalCalculado).toFixed(2)}</td>
            <td>{Number(articulo.precioFinal).toFixed(2)}</td>
            <td>{articulo.articulo.pesable ? 'SI' : 'NO'}</td>
            <td>{articulo.articulo.pesoPromedio}</td>
            <td>{condicionStock(articulo.cantidad)}</td>
            <td>{articulo.articulo.unidadMedida.nombre}</td>
            <td>
                {Number.parseFloat(articulo.updatedAt)
                    ? articulo.updatedAt.split('T')[0]
                    : '-'}
            </td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverStock(articulo)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemStockSucursalEliminado)
