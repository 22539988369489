import React, { Fragment, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// modals
import ItemDetallePago from './ItemDetallePago'

// services
import { getPagosByCompra } from '../../../Services/proveedorPagosService'
import { getPdfReporteHistorialPagosDeCompras } from '../../../Services/reporteService'

import Swal from 'sweetalert2'

const ModalDetallePago = ({ item, history }) => {
    const estado = (estado) => {
        if (estado === 'ADEUDADA')
            return <span className="text-primary">ADEUDADA</span>
        if (estado === 'PAGADA_PARCIALMENTE')
            return <span className="text-warning">PAGADA PARCIALMENTE</span>
        if (estado === 'PAGADA')
            return <span className="text-success">PAGADA</span>
    }

    const [pagos, setPagos] = useState([])

    const getPagos = () => {
        getPagosByCompra(item.id)
            .then((res) => {
                setPagos(res.data.content)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    //const ImprimirComandera = () => {
    //let w = window.open();
    //let value = document.getElementById('modalPagos'+item.idCompra);
    //w.document.write(value.innerHTML);
    //w.print();
    //w.close();
    //}

    const hadleImprimir = async (pagos) => {
        try {
            const res = await getPdfReporteHistorialPagosDeCompras(pagos)
            const contenido = encodeURI(res)
            let pdfWindow = window.open('')
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64,${contenido}'>
                </iframe>`
            )
        } catch (error) {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hubo un error vuelve a intentar!',
            })
        }
    }

    return (
        <Fragment>
            <button
                type="button"
                className="btn text-success"
                data-toggle="modal"
                data-target={'#modalPagos' + item.id}
                onClick={() => getPagos()}
            >
                <FontAwesomeIcon icon={faMoneyBillWave} /> Detalle Pagos
            </button>

            <div
                className="modal fade"
                id={'modalPagos' + item.id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalPagosTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalPagosTitle">
                                <b>Detalle Pagos</b>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <span className="col-md">
                                    <b>Fecha:</b>
                                </span>
                                <span className="col-md">
                                    <b>Total:</b>
                                </span>
                                <span className="col-md">
                                    <b>Estado:</b>
                                </span>
                            </div>

                            <div className="row">
                                <span className="col-md">
                                    {item.createdAt.split('T')[0]}
                                </span>
                                <span className="col-md">
                                    ${item.total.toFixed(2)}
                                </span>
                                <span className="col-md">
                                    {estado(item.estado)}
                                </span>
                            </div>

                            <div className="row">
                                <span className="col-md">
                                    <b>Proveedor:</b>
                                </span>
                                <span className="col-md">
                                    <b>Tipo Comprobante:</b>
                                </span>
                                <span className="col-md">
                                    <b>Numero:</b>
                                </span>
                            </div>

                            <div className="row">
                                <span className="col-md">
                                    {item.proveedor.nombre}
                                </span>
                                <span className="col-md">
                                    {item.tipoComprobante.nombre}
                                </span>
                                <span className="col-md">
                                    {item.numeroComprobante}
                                </span>
                            </div>

                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Medio de pago</th>
                                        <th scope="col">Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pagos.map((pago) => (
                                        <ItemDetallePago
                                            key={parseInt(pago.id)}
                                            item={pago}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success"
                                data-dismiss="modal"
                                onClick={() => hadleImprimir(pagos)}
                            >
                                Imprimir
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ModalDetallePago)
