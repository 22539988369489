import React, { Fragment } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//style
import './Colours.css'

const Subtitulo = ({ title, icon, color }) => {
    return (
        <Fragment>
            <h2 className="text-black pt-2">
                {' '}
                <FontAwesomeIcon icon={icon} /> {title}
            </h2>
            <h3 className="">&nbsp;</h3>
        </Fragment>
    )
}

export default Subtitulo
