import React, { Fragment, useState, useEffect } from 'react'

//components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTruck } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

//services
import { saveCuentasCorriente } from '../../Services/cuentaCorrienteProveedorService'
import { getAllProveedores } from '../../Services/proveedorService'
import { saveError } from '../../Services/errorService'

import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import validationsConfig from './ValidationsConfigCuentaCorrienteProveedor'
import getErrorMessageInValidation from '../Utiles/validationsInForm'

function AddCuentasCorrientes({ history }) {
    const [proveedor, setProveedor] = useState({})
    const [isLoad, setIsLoad] = useState(true)

    const [proveedores, setProveedores] = useState([])
    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        if (isLoad) {
            getAllProveedores(true, 0, '', 1000)
                .then((res) => {
                    setProveedores(res.data.content)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const addCuentaCorrienteSubmit = (e) => {
        // creamos la sucursal
        let cuentaCorriente = {
            proveedorId: proveedor,
        }

        saveCuentasCorriente(cuentaCorriente)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear una cuenta corriente',
                    detalle: error.message,
                    entidad: 'CUENTA_CORRIENTE_PROVEEDOR',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    title: 'La cuenta del proveedor ya existe',
                    type: 'warning',
                    showConfirmButton: false,
                    timer: 1000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faTruck}
                        title={'Cuentas Corrientes Proveedores'}
                    />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={handleSubmit(addCuentaCorrienteSubmit)}>
                        <div className="form-row">
                            <div
                                className="form-group col-md-6"
                                style={{
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    display: 'block',
                                }}
                            >
                                <label htmlFor="inputProveedor">
                                    Proveedor
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faTruck} />
                                    </span>
                                    <select
                                        id="inputProveedor"
                                        className="custom-select"
                                        name="proveedor"
                                        onChange={(e) =>
                                            setProveedor(e.target.value)
                                        }
                                        ref={register(
                                            validationsConfig.proveedor
                                        )}
                                    >
                                        <option value={'null'}>
                                            -- Seleccione un Proveedor --
                                        </option>
                                        {proveedores.map((proveedor, index) => (
                                            <option
                                                key={index}
                                                value={proveedor.id}
                                            >
                                                {proveedor.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <small className="form-text text-danger">
                                    {errors.proveedor &&
                                        getErrorMessageInValidation(
                                            'proveedor',
                                            errors.proveedor,
                                            validationsConfig
                                        )}
                                </small>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Cuenta
                                Corriente
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddCuentasCorrientes)
