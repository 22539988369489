import {
    GET_MEDIOS_PAGOS,
    GET_MEDIO_PAGO,
    ADD_MEDIO_PAGO,
    EDIT_MEDIO_PAGO,
    DELETE_MEDIO_PAGO,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    mediosPagos: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MEDIOS_PAGOS:
            return {
                ...state,
                mediosPagos: action.payload,
            }
        case GET_MEDIO_PAGO:
            return {
                ...state,
                mediosPago: action.payload,
            }
        case ADD_MEDIO_PAGO:
            return {
                ...state,
                mediosPagos: [...state.mediosPagos, action.payload],
            }
        case EDIT_MEDIO_PAGO:
            return {
                ...state,
                mediosPagos: state.mediosPagos.map((mediosPago) =>
                    mediosPago.idMedioPago === action.payload.idMedioPago
                        ? (mediosPago = action.payload)
                        : mediosPago
                ),
            }
        case DELETE_MEDIO_PAGO:
            return {
                ...state,
                mediosPagos: state.mediosPagos.filter(
                    (mediosPago) => mediosPago.idMedioPago !== action.payload
                ),
            }
        default:
            return state
    }
}
