import React, { Fragment, useRef } from 'react'

// router
import { withRouter } from 'react-router-dom'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faClipboardList,
    faUserCircle,
    faCalendarMinus,
    faMinusCircle,
    faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons'

// services
import { editByIdCuentasCorriente } from '../../Services/cuentaCorrienteProveedorService'
import { saveError } from '../../Services/errorService'

// swal
import Swal from 'sweetalert2'

const EditCuentaCorriente = ({ history, location }) => {
    const debeRef = useRef('')
    const fechaUltimoPagoRef = useRef('')
    const fechaProximoPagoRef = useRef('')

    const cuentaCorriente = location.state.cuentaCorriente

    const editSucursalSubmit = (e) => {
        e.preventDefault()

        // formamos la sucursal editada
        let cuentaCorrienteUpdated = {
            id: parseInt(cuentaCorriente.id, 10),
            debo: debeRef.current.value,
            fechaUltimoPago: fechaUltimoPagoRef.current.value,
            fechaProximoPago: fechaProximoPagoRef.current.value,
        }

        editByIdCuentasCorriente(cuentaCorrienteUpdated)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar editar una cuenta corriente',
                    detalle: error.message,
                    entidad: 'CUENTA_CORRIENTE_PROVEEDOR',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al editar la cuenta corriente, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const isDateNull = (fecha) => {
        if (fecha === null) return null
        else {
            let fechaFinal = fecha.split('T')[0]
            return fechaFinal
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faClipboardList}
                        title={'Cuentas Corrientes'}
                    />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={editSucursalSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputProveedor">
                                    Proveedor
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputProveedor"
                                        name="proveedor"
                                        className="form-control"
                                        defaultValue={cuentaCorriente.proveedor.nombre.toLocaleUpperCase()}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDebe">Debo</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDebe"
                                        name="debe"
                                        className="form-control"
                                        ref={debeRef}
                                        defaultValue={cuentaCorriente.debo}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputUltimoPago">
                                    Fecha Ultimo Pago
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faCalendarMinus}
                                        />
                                    </span>
                                    <input
                                        type="date"
                                        id="inputUltimoPago"
                                        name="ultimoPago"
                                        className="form-control"
                                        ref={fechaUltimoPagoRef}
                                        defaultValue={isDateNull(
                                            cuentaCorriente.fechaUltimoPago
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputProximoPago">
                                    Fecha Proximo Pago
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faCalendarPlus}
                                        />
                                    </span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="inputProximoPago"
                                        name="proximoPago"
                                        ref={fechaProximoPagoRef}
                                        defaultValue={isDateNull(
                                            cuentaCorriente.fechaProximoPago
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-danger"
                            >
                                <FontAwesomeIcon icon={faEdit} /> Editar Cuenta
                                Corriente
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditCuentaCorriente)
