import axios from 'axios'
import { url } from './global'

export const getAllVentasMediospagosByIdVenta = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/ventas/${id}/medio-pagos`, {
        headers,
    })
    return res
}

export const getVentaMediopagoByIdVenta = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/ventas/${id}/medio-pagos/4`, {
        headers,
    })
    return res
}

export const editByIdVentasMediospagos = async (venta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/ventas-mediospagos/${venta.idVenta}`,
        venta,
        { headers }
    )
    return res
}

export const deleteByIdVentasMediospagos = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/ventas-mediospagos/${id}`, {
        headers,
    })
    return res
}

export const saveVentasMediospagos = async (venta) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/ventas-mediospagos`, venta, {
        headers,
    })
    return res
}
