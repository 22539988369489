import React, { Fragment, useEffect, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileImport,
    faCalendarAlt,
    faFileExcel,
    faUser,
} from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// services
import { getVentaTotalByClienteAndMedioPago } from '../../Services/informesService'
import { getExcelReporteCuentaCorriente } from '../../Services/reporteService'
import { getAllClientes } from '../../Services/clienteService'

// alert swal
import Swal from 'sweetalert2'

const InformeVentaAdeudada = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const FormatDate = (date) => {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day

        return year + '-' + month + '-' + day
    }

    const [isLoad, setIsLoad] = useState(true)
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState()
    const [fechaDesde, setFechaDesde] = useState(FormatDate(new Date()))
    const [fechaHasta, setFechaHasta] = useState(FormatDate(new Date()))

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        if (isLoad) {
            getAllClientes(true, 0, '', 1000)
                .then((res) => {
                    setClientes(res.data.content)
                })
                .catch((err) => console.log(err))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const handleReporteVentaExcel = async () => {
        let ventasTotalesMedioPago

        try {
            ventasTotalesMedioPago = (
                await getVentaTotalByClienteAndMedioPago(
                    fechaDesde,
                    fechaHasta,
                    cliente
                )
            ).data

            const excel = await getExcelReporteCuentaCorriente(
                ventasTotalesMedioPago,
                null
            )

            const contenido = encodeURI(excel.data)
            let excelWindow = window.open('')
            excelWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contenido}'>
                </iframe>`
            )
            setTimeout(() => {
                excelWindow.close()
            }, 3000)
        } catch (error) {
            Swal.fire({
                title: 'No hay datos para estas fechas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faFileImport}
                        title={'Informe Ventas Adeudadas'}
                    />
                </div>

                <div className="bg-gris p-3">
                    <div className="d-flex bd-highlight mb-3">
                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputFechaDesde">Desde *</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="inputFechaDesde"
                                    name="fechaDesde"
                                    value={fechaDesde}
                                    onChange={(e) =>
                                        setFechaDesde(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputFechaHasta">Hasta *</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="inputFechaHasta"
                                    value={fechaHasta}
                                    name="fechaHasta"
                                    onChange={(e) =>
                                        setFechaHasta(e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="p-2 bd-highlight">
                            <label htmlFor="inputCliente">Cliente</label>
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                                <select
                                    id="inputCliente"
                                    className="custom-select"
                                    name="cliente"
                                    value={cliente}
                                    onChange={(e) => setCliente(e.target.value)}
                                >
                                    <option
                                        key={null}
                                        value={undefined}
                                    ></option>
                                    {clientes.map((cliente, index) => (
                                        <option
                                            key={index}
                                            value={cliente.id.persona.id}
                                        >
                                            {cliente.id.persona.nombre +
                                                ' ' +
                                                cliente.id.persona.apellido}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div
                            className="ms-auto p-2 bd-highlight"
                            style={{ marginTop: 20 }}
                        >
                            <button
                                className="btn btn-outline-success btn-lg"
                                onClick={() => {
                                    handleReporteVentaExcel()
                                }}
                            >
                                <FontAwesomeIcon icon={faFileExcel} /> Generar
                                Reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(InformeVentaAdeudada)
