import axios from 'axios'
import { url } from './global'

export const getAllMarcas = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/marcas?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getAllMarcasByNombre = async (nombre) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(`${url}/marcas-like/${nombre}`, { headers })
    return res
}

export const getByIdMarcas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/marcas/${id}`, { headers })
    return res
}

export const editByIdMarcas = async (marca) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/v2/marcas/${marca.id}`, marca, {
        headers,
    })
    return res
}

export const deleteByIdMarcas = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/marcas/${id}`, { headers })
    return res
}

export const saveMarcas = async (marca) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/marcas`, marca, { headers })
    return res
}

export const recoverMarcaDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(`${url}/v2/marcas/${id}/recover`, {}, { headers })
    return res
}
