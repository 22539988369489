import React, { Fragment, useEffect, useState, useRef } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faMoneyBillWave,
    faDollarSign,
    faCashRegister,
    faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// services
import { getAllPuntoVentas } from '../../Services/puntoVentaService'
import { save, getCajaActivaByPtoVenta } from '../../Services/cajaService'

// alert swal
import Swal from 'sweetalert2'

const OpenCaja = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))

    const [puntosVentas, setPuntosVentas] = useState([])
    const [puntoVenta, setPuntoVenta] = useState()

    const sucursal = token.sucursal
    const [isLoad, setIsLoad] = useState(true)
    const [isCaja, setIsCaja] = useState(true)

    const inputMontoRef = useRef(null)

    useEffect(() => {
        if (isLoad) {
            //TODO: revisar para conseguir puntos de venta por sucursal
            getAllPuntoVentas(sucursal.id, true, 0)
                .then((res) => {
                    setPuntosVentas(res.data.content)
                    setIsLoad(false)
                })
                .catch((error) => console.log(error.response))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const verificarCaja = () => {
        // filtro el punto de venta seleccionada mediante su id
        let ptoFiltered = puntosVentas.filter(function (pv) {
            return pv.id === parseInt(puntoVenta, 10)
        })

        getCajaActivaByPtoVenta(puntoVenta)
            .then(async (res) => {
                let exist = res.data.exist
                setIsCaja(res.data.caja)

                if (exist === true) {
                    Swal.fire({
                        title: 'Ya existe una caja abierta!',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    sessionStorage.setItem(
                        'ptoventa',
                        JSON.stringify(ptoFiltered[0])
                    )
                    sessionStorage.setItem(
                        'caja',
                        JSON.stringify(res.data.caja)
                    )
                    if (ptoFiltered[0].datoFiscal.apiKey) {
                        sessionStorage.setItem(
                            'api-key',
                            ptoFiltered[0].datoFiscal.apiKey
                        )
                    }
                    history.push('./vender')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const iniciarCaja = () => {
        // filtro el punto de venta seleccionada mediante su id
        let ptoFiltered = puntosVentas.filter(function (pv) {
            return pv.id === parseInt(puntoVenta, 10)
        })

        let caja = {
            puntoVentaId: ptoFiltered[0].id,
            montoInicial: inputMontoRef.current.value,
        }

        save(caja)
            .then((res) => {
                Swal.fire({
                    title: 'Se abrio una caja correctamente!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                })
                sessionStorage.setItem(
                    'ptoventa',
                    JSON.stringify(ptoFiltered[0])
                )
                sessionStorage.setItem('caja', JSON.stringify(res.data.caja))
                if (ptoFiltered[0].datoFiscal.apiKey) {
                    sessionStorage.setItem(
                        'api-key',
                        ptoFiltered[0].datoFiscal.apiKey
                    )
                }
                history.push('/vender')
            })
            .catch((error) => {
                Swal.fire({
                    title: 'No se pudo crear la caja, ingrese monto inicial!',
                    type: 'warning',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faMoneyBillWave}
                        title={'Apertura de caja'}
                    />
                </div>

                <div className="bg-gris p-3">
                    {!isCaja ? (
                        <div>
                            <div className="row justify-content-center mt-3">
                                <div className="col-6">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text"
                                            id="inputGroupPrepend"
                                        >
                                            <FontAwesomeIcon
                                                icon={faDollarSign}
                                            />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            step="any"
                                            id="inputCosto"
                                            name="costo"
                                            ref={inputMontoRef}
                                            placeholder="Ingrese monto inicial"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center pt-2">
                                <div className="col-6 text-center">
                                    <button
                                        className="btn btn-outline-success btn-lg"
                                        onClick={() => iniciarCaja()}
                                    >
                                        Iniciar caja{' '}
                                        <FontAwesomeIcon
                                            icon={faCashRegister}
                                        />
                                    </button>
                                    <button
                                        className="btn btn-outline-info btn-lg ml-1"
                                        onClick={() => setIsCaja(true)}
                                    >
                                        Volver{' '}
                                        <FontAwesomeIcon
                                            icon={faArrowAltCircleLeft}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="row justify-content-center">
                                <div className="col-6">
                                    <label htmlFor="inputComprobante">
                                        Seleccion un punto de venta
                                    </label>
                                    <select
                                        id="inputPuntoVenta"
                                        className="custom-select"
                                        name="puntoVenta"
                                        value={puntoVenta}
                                        onChange={(e) =>
                                            setPuntoVenta(e.target.value)
                                        }
                                    >
                                        <option value={null}>
                                            -- Seleccione un punto de venta --
                                        </option>
                                        {puntosVentas.map((pv, index) => (
                                            <option key={index} value={pv.id}>
                                                {pv.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row justify-content-center pt-2">
                                <div className="col-6 text-center">
                                    <button
                                        className="btn btn-outline-success btn-lg"
                                        onClick={() => verificarCaja()}
                                    >
                                        Seleccionar{' '}
                                        <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(OpenCaja)
