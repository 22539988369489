import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverArticulosDeleted } from '../../Services/articuloService'

// Sweet alert
import Swal from 'sweetalert2'

// router
import { withRouter } from 'react-router-dom'

const ItemArticuloEliminado = ({ articulo, history, setIsLoad }) => {
    const recoverArticuloSubmit = (articulo) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverArticulosDeleted(articulo.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'El articulo fue recuperado.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{articulo.codigo}</th>
            <td>{articulo.nombre}</td>
            <td>{articulo.pesable ? 'SI' : 'NO'}</td>
            <td>{articulo.balanza ? 'SI' : 'NO'}</td>
            <td>{articulo.compuesto ? 'SI' : 'NO'}</td>
            <td>${articulo.costo.toFixed(2)}</td>
            <td>${articulo.precioFinal.toFixed(2)}</td>
            <td>{articulo.porcentajeGanancia}%</td>
            <td>{articulo.familia.nombre}</td>
            <td>{articulo.marca.nombre}</td>
            <td>{articulo.unidadMedida.nombre}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverArticuloSubmit(articulo)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemArticuloEliminado)
