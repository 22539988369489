import axios from 'axios'
import { url } from './global'

export const getAllProveedores = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/proveedores?${
            nombre ? `nombre=${nombre}&` : ''
        }activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdProveedor = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/proveedores/${id}`, { headers })
    return res
}

export const editByIdProveedor = async (proveedor) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/proveedores/${proveedor.id}`,
        proveedor,
        { headers }
    )
    return res
}

export const deleteByIdProveedor = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/proveedores/${id}`, { headers })
    return res
}

export const saveProveedor = async (proveedor) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/proveedores`, proveedor, {
        headers,
    })
    return res
}

export const recoverProveedorDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/proveedores/${id}/recover`,
        {},
        { headers }
    )
    return res
}
