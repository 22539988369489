import React from 'react'

// routes
import { withRouter } from 'react-router-dom'

const ItemInforme = ({ data }) => {
    if (data.articulo) {
        return (
            <tr>
                <th scope="row">{data.articulo.codigo}</th>
                <td>{data.articulo.nombre}</td>
                <td>{data.articulo.pesable ? 'SI' : 'NO'}</td>
                <td>
                    {Number.parseFloat(
                        data.articulo.pesable
                            ? data.cantidad / 1000
                            : data.cantidad
                    )}
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <th scope="row">{data.medioPago.id}</th>
                <td>{data.medioPago.nombre}</td>
                <td>{data.monto}</td>
            </tr>
        )
    }
}

export default withRouter(ItemInforme)
