import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

//utiles
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
//service
import { editByIdProveedor } from '../../Services/proveedorService'
import { saveError } from '../../Services/errorService'
//font
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faDesktop,
    faAddressBook,
    faTruckMoving,
    faPhone,
} from '@fortawesome/free-solid-svg-icons'

//formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

// Sweet alert
import Swal from 'sweetalert2'

import Header from '../Header/Header'

function EditProveedor({ history, location }) {
    const proveedor = location.state.proveedor

    const formik = useFormik({
        initialValues: {
            nombre: proveedor.nombre,
            telefono: proveedor.telefono,
            direccion: proveedor.direccion,
            url: proveedor.url,
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Obligatorio'),
        }),
        onSubmit: (values) => editProveedor(values),
    })

    const editProveedor = (values) => {
        const proveedorUpdated = {
            ...values,
            id: proveedor.id,
        }

        editByIdProveedor(proveedorUpdated)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar editar el proveedor',
                    detalle: error.message,
                    entidad: 'PROVEEDOR',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al editar el proveedor, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.log(error.response.data)
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faTruckMoving} title={'Proveedor'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faTruckMoving} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Carnes Tucumán..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPhone} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Código + número sin 15"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">
                                    Direccion
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. San Martín 1234"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputUrl">Url</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faDesktop} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputUrl"
                                        name="url"
                                        placeholder="Ej. www.ejemplo.com.ar"
                                        value={formik.values.url}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faEdit} /> Editar
                                Proveedor
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditProveedor)
