import React, { Fragment } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'
import ItemDetallePresupuesto from './ItemDetallePresupuesto'

const ModalDetallePresupuesto = ({ item }) => {
    return (
        <Fragment>
            <button
                type="button"
                className="btn text-success"
                data-toggle="modal"
                data-target={'#modalPresupuesto' + item.id}
            >
                <FontAwesomeIcon icon={faList} /> Detalle
            </button>

            <div
                className="modal fade"
                id={'modalPresupuesto' + item.id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalPresupuestoTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5
                                className="modal-title"
                                id="modalPresupuestoTitle"
                            >
                                <b>Detalle Presupuesto</b>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <p className="col-md">
                                    <b>Fecha:</b>
                                </p>
                                <p className="col-md">
                                    <b>Total:</b>
                                </p>
                                <p className="col-md">
                                    <b>Fecha Vencimiento:</b>
                                </p>
                            </div>
                            <div className="row">
                                <p className="col-md">
                                    {item.createdAt.split('T')[0]}
                                </p>
                                <p className="col-md">
                                    ${item.total.toFixed(2)}
                                </p>
                                <p className="col-md">
                                    {item.fechaVencimiento.split('T')[0]}
                                </p>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Articulo</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.items.map((item) => (
                                        <ItemDetallePresupuesto
                                            key={parseInt(item.id)}
                                            item={item}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ModalDetallePresupuesto)
