import {
    GET_VENTAS,
    GET_VENTA,
    ADD_VENTA,
    EDIT_VENTA,
    DELETE_VENTA,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    ventas: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_VENTAS:
            return {
                ...state,
                ventas: action.payload,
            }
        case GET_VENTA:
            return {
                ...state,
                venta: action.payload,
            }
        case ADD_VENTA:
            return {
                ...state,
                ventas: [...state.ventas, action.payload],
            }
        case EDIT_VENTA:
            return {
                ...state,
                ventas: state.ventas.map((venta) =>
                    venta.idVenta === action.payload.idVenta
                        ? (venta = action.payload)
                        : venta
                ),
            }
        case DELETE_VENTA:
            return {
                ...state,
                ventas: state.ventas.filter(
                    (venta) => venta.idVenta !== action.payload
                ),
            }
        default:
            return state
    }
}
