import React, { Fragment, useState, useEffect } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCashRegister,
    faCopyright,
    faPlus,
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

// services
import { savePuntoVentas } from '../../Services/puntoVentaService'
import { saveError } from '../../Services/errorService'
import { getAllSucursales } from '../../Services/sucursalService'
import { getAllDatoFiscales } from '../../Services/datoFiscalService'

// components
import Swal from 'sweetalert2'
import Header from '../Header/Header'

function AddPuntoVenta({ history }) {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const [sucursales, setSucursales] = useState([])
    const [datoFiscales, setDatoFiscales] = useState([])
    const [isLoad, setIsLoad] = useState(true)

    const formik = useFormik({
        initialValues: {
            nombre: '',
            ip: '',
            sucursal: {},
            datoFiscal: {},
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('El nombre es requerido'),
            ip: Yup.string(),
            sucursal: Yup.object().required('La sucursal es requerida'),
            datoFiscal: Yup.object(),
        }),
        onSubmit: (values) => addPuntoVenta(values),
    })

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        document.getElementById('inputNombre').focus()
        if (isLoad) {
            getAllSucursales(true, 0, '', 100)
                .then((res) => setSucursales(res.data.content))
                .catch((err) => console.log('error'))
            getAllDatoFiscales(true, 0, '', 100)
                .then((res) => setDatoFiscales(res.data.content))
                .catch((err) => console.log('error'))
            setIsLoad(false)
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addPuntoVenta = (values) => {
        // creamos el punto de venta
        let puntoVenta = {
            nombre: values.nombre,
            sucursalId: values.sucursal.id,
            ...(values.ip.length ? { ip: values.ip } : { ip: '' }),
            ...(Object.keys(values.datoFiscal).length
                ? { datoFiscalId: values.datoFiscal.id }
                : {}),
        }

        savePuntoVentas(puntoVenta)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear un punto de venta',
                    detalle: error.message,
                    entidad: 'PUNTO_VENTA',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al crear el punto de venta, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2500,
                })
                console.error(error)
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faCashRegister} title={'Punto de Venta'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Coca Cola, Bimbo..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputIp">Ip</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputIp"
                                        name="ip"
                                        className="form-control"
                                        placeholder="127.0.0.1"
                                        value={formik.values.ip}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.ip && formik.errors.ip ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.ip}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputSucursal">Sucursal</label>
                                <div>
                                    <Select
                                        placeholder={'Seleccionar una sucursal'}
                                        options={sucursales}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'sucursal',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.sucursal &&
                                formik.errors.sucursal ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.sucursal}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDatoFiscal">
                                    Dato Fiscal
                                </label>
                                <div>
                                    <Select
                                        placeholder={
                                            'Seleccionar una certificado'
                                        }
                                        options={datoFiscales}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'datoFiscal',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.razonSocial
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.datoFiscal &&
                                formik.errors.datoFiscal ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.datoFiscal}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Punto
                                Venta
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddPuntoVenta)
