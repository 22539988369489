import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

// services
import { recoverListaPrecioDeleted } from '../../Services/listaPrecioService'

const ItemListaPrecioEliminados = ({ lista, history, setIsLoad }) => {
    const recoverListaPrecioSubmit = (listaPrecio) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverListaPrecioDeleted(listaPrecio.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'La lista de precio fue recuperada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    return (
        <tr key={parseInt(lista.id)}>
            <th scope="row">{lista.id}</th>
            <td>{lista.nombre}</td>
            <td>{lista.porcentajeMarcacion}</td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverListaPrecioSubmit(lista)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemListaPrecioEliminados)
