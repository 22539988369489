import axios from 'axios'
import { url } from './global'

export const getComprasByProveedor = async (proveedor, activo, page, size) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/compras/estado?proveedor=${proveedor}&estadoCompraFirst=2&estadoCompraSecond=1&activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

// SAVE PAGOS
export const savePago = async (pago) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(
        `${url}/v2/cuenta-corriente/proveedores/compra/pagos`,
        pago,
        { headers }
    )
    return res
}

// GET PAGOS BY COMPRA
export const getPagosByCompra = async (compra) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(
        `${url}/v2/cuenta-corriente/proveedores/compra/${compra}/pagos`,
        { headers }
    )
    return res
}
