import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

//Font & Alerts
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faUserCircle,
    faUserEdit,
    faAt,
    faMobileAlt,
    faIdCard,
    faMapMarkedAlt,
    faBirthdayCake,
    faIdCardAlt,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons'

//Services
import { saveEmpleado } from '../../Services/empleadoService'
import { saveError } from '../../Services/errorService'
import Header from '../Header/Header'

//Formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

function AddEmpleados({ history }) {
    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            dni: '',
            cuilCuit: '',
            fechaNacimiento: '',
            correo: '',
            telefono: '',
            direccion: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Obligatorio.'),
            apellido: Yup.string(),
            correo: Yup.string().email('Formato invalido.'),
            telefono: Yup.number(),
            dni: Yup.number().required('Obligatorio.'),
            cuilCuit: Yup.string(),
            direccion: Yup.string(),
            fechaNacimiento: Yup.date(),
        }),
        onSubmit: (values) => addEmpleadoSubmit(values),
    })

    const addEmpleadoSubmit = (values) => {
        let empleado = {
            ...values,
        }
        saveEmpleado(empleado)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear un empleado',
                    detalle: error.message,
                    entidad: 'EMPLEADO',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    title:
                        error.response.data.message ||
                        'No se pudo crear el empleado',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faUserTie} title={'Empleados'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Jose Manuel"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserEdit} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputApellido"
                                        name="apellido"
                                        placeholder="Ej Gutierrez..."
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputDni">DNI</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDni"
                                        name="dni"
                                        className="form-control"
                                        placeholder="Ej. 4234890"
                                        value={formik.values.dni}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.dni && formik.errors.dni ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.dni}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCuilCuit">CUIL/CUIT</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faIdCardAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCuilCuit"
                                        name="cuilCuit"
                                        placeholder="Ej. 20341234562"
                                        value={formik.values.cuilCuit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFechaNacimiento">
                                    Fecha Nacimiento
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faBirthdayCake}
                                        />
                                    </span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="inputFechaNacimiento"
                                        name="fechaNacimiento"
                                        value={formik.values.fechaNacimiento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCorreo">
                                    Correo Electronico
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faAt} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputCorreo"
                                        name="correo"
                                        className="form-control"
                                        placeholder="Ej. perez@email.com"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputTelefono">Telefono</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputTelefono"
                                        name="telefono"
                                        placeholder="Ej. +54381234098"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDireccion">
                                    Direccion
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                        />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDireccion"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Pje. La Paz 123"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row"></div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Empleado
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddEmpleados)
