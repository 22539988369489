import React, { Fragment } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright, faPlus } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

// services
import { saveMarcas } from '../../Services/marcaService'
import { saveError } from '../../Services/errorService'

import Swal from 'sweetalert2'
import Header from '../Header/Header'

function AddMarcas({ history }) {
    const formik = useFormik({
        initialValues: {
            nombre: '',
            ganancia: 0.0,
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('El nombre es requerido'),
            ganancia: Yup.number().min(0, 'No puede ser menor a 0'),
        }),
        onSubmit: (values) => addMarca(values),
    })

    const addMarca = (values) => {
        // creamos la marca
        let marca = {
            ...values,
        }

        saveMarcas(marca)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear una marca',
                    detalle: error.message,
                    entidad: 'MARCA',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al crear la marca, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2500,
                })
                console.error(error)
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faCopyright} title={'Marcas'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Coca Cola, Bimbo..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputGanancia">Ganancia</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCopyright} />
                                    </span>
                                    <input
                                        type="number"
                                        id="inputGanancia"
                                        name="ganancia"
                                        className="form-control"
                                        placeholder="10"
                                        value={formik.values.ganancia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.ganancia &&
                                formik.errors.ganancia ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.ganancia}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Marca
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddMarcas)
