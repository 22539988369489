import React from 'react'

// router
import { withRouter } from 'react-router-dom'

const ItemDetallePresupuesto = ({ item }) => {
    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>
                {item.articulo
                    ? item.articulo.nombre
                    : item.departamento.nombre}
            </td>
            <td>{item.cantidad}</td>
            <td>${item.precio.toFixed(2)}</td>
        </tr>
    )
}

export default withRouter(ItemDetallePresupuesto)
