import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom'

// services
import { recoverByIdVentas } from '../../Services/ventaService'

// moment
import moment from 'moment'
import 'moment-timezone'

// alert swal
import Swal from 'sweetalert2'

const ItemVentaEliminada = ({ venta, setIsLoad }) => {
    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>
        if (estado === 'PAGADA_PARCIALMENTE')
            return <p className="text-warning">PAGADA PARCIALMENTE</p>
        if (estado === 'ADEUDADA')
            return <p className="text-primary">ADEUDADA</p>
        if (estado === 'CANCELADA')
            return <p className="text-warning">CANCELADA</p>
    }

    const recoverVenta = (venta) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                recoverByIdVentas(venta.id)
                    .then((res) => {
                        Swal.fire(
                            'Recuperado!',
                            'La venta de la sucursal fue recuperada.',
                            'success'
                        )
                        setIsLoad(true)
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!',
                        })
                        console.log(error.response)
                    })
            }
        })
    }

    const fechaHora = moment(venta.createdAt)
        .tz('America/Argentina/Buenos_Aires')
        .format()

    return (
        <tr>
            <th scope="row">{venta.numeroComprobante}</th>
            <td>${venta.total.toFixed(2)}</td>
            <td>{estado(venta.estado)}</td>
            <td>
                {fechaHora.split('T')[0]} /{' '}
                {fechaHora.split('T')[1].replace('-03:00', '')}
            </td>
            <td>{venta.sucursal.nombre}</td>
            <td>{venta.tipoComprobante.nombre}</td>
            <td>
                {venta.cliente.id.persona.apellido},{' '}
                {venta.cliente.id.persona.nombre}
            </td>
            <td>
                <button
                    className="btn text-warning"
                    onClick={() => {
                        recoverVenta(venta)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Recuperar
                </button>
            </td>
        </tr>
    )
}

export default withRouter(ItemVentaEliminada)
