import {
    GET_MARCAS,
    GET_MARCA,
    ADD_MARCA,
    EDIT_MARCA,
    DELETE_MARCA,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    marcas: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MARCAS:
            return {
                ...state,
                marcas: action.payload,
            }
        case GET_MARCA:
            return {
                ...state,
                marca: action.payload,
            }
        case ADD_MARCA:
            return {
                ...state,
                marcas: [...state.marcas, action.payload],
            }
        case EDIT_MARCA:
            return {
                ...state,
                marcas: state.marcas.map((marca) =>
                    marca.idMarca === action.payload.idMarca
                        ? (marca = action.payload)
                        : marca
                ),
            }
        case DELETE_MARCA:
            return {
                ...state,
                marcas: state.marcas.filter(
                    (marca) => marca.idMarca !== action.payload
                ),
            }
        default:
            return state
    }
}
