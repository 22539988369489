import React, { Fragment, useState, useEffect } from 'react'
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faCalculator,
    faPercentage,
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import {
    EditaListasPrecio,
    GetListasPrecio,
} from '../../Actions/ListaPrecioAction'
import {
    getByIdListasPrecios,
    editByIdListasPrecios,
} from '../../Services/listaPrecioService'
import { useForm } from 'react-hook-form'
import validationsConfig from './ValidationConfigListaPrecio'
import getErrorMessageInValidation from '../Utiles/validationsInForm'
import Header from '../Header/Header'

const EditListaPrecio = ({ history, location }) => {
    const [nombre, setNombre] = useState('')
    const [porcentajeMarcacion, setPorcentajeMarcacion] = useState('')
    const [isLoad, setIsLoad] = useState(true)
    const id = location.state.id
    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        if (isLoad) {
            getByIdListasPrecios(id)
                .then((res) => {
                    const { nombre, porcentajeMarcacion } = res.data
                    setNombre(nombre)
                    setPorcentajeMarcacion(porcentajeMarcacion)
                })
                .catch((err) => console.log(err))
            setIsLoad(false)
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const editarListaPrecioSubmit = (e) => {
        const listaPrecioUpdated = {
            id: parseInt(id, 10),
            nombre,
            porcentajeMarcacion,
        }

        editByIdListasPrecios(listaPrecioUpdated)
            .then((res) => {
                history.goBack()
            })
            .catch((error) => {
                console.log(error.response)
                this.setState({ error: true })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faCalculator} title={'Lista de Precios'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={handleSubmit(editarListaPrecioSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faCalculator} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lista 1"
                                        defaultValue={nombre}
                                        onChange={(e) =>
                                            setNombre(e.target.value)
                                        }
                                        ref={register(validationsConfig.nombre)}
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.nombre &&
                                        getErrorMessageInValidation(
                                            'nombre',
                                            errors.nombre,
                                            validationsConfig
                                        )}
                                </small>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPorcentajeMarcacion">
                                    Porcentaje Marcacion
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPercentage} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputPorcentajeMarcacion"
                                        name="porcentajeMarcacion"
                                        placeholder="Ej. 1, 2, 100"
                                        defaultValue={porcentajeMarcacion}
                                        onChange={(e) =>
                                            setPorcentajeMarcacion(
                                                e.target.value
                                            )
                                        }
                                        ref={register(
                                            validationsConfig.porcentajeMarcacion
                                        )}
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.porcentajeMarcacion &&
                                        getErrorMessageInValidation(
                                            'porcentajeMarcacion',
                                            errors.porcentajeMarcacion,
                                            validationsConfig
                                        )}
                                </small>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-danger"
                            >
                                <FontAwesomeIcon icon={faEdit} /> Editar Lista
                                Precio
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    listaPrecio: state.listasPrecios.listaPrecio,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getListaPrecio: (id) => dispatch(GetListasPrecio(id)),
        editarListaPrecio: (listaPrecio) =>
            dispatch(EditaListasPrecio(listaPrecio)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditListaPrecio)
