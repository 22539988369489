import React, { Fragment, useEffect, useState } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faClipboardList,
    faSearch,
    faTrash,
} from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import ItemCuentaCorriente from './ItemCuentaCorriente'

// route
import { withRouter } from 'react-router-dom'

// services
import { getAllCuentasCorrientesPage } from '../../Services/cuentaCorrienteClienteService'

// alert swal
import Swal from 'sweetalert2'
import Header from '../Header/Header'

const CuentasCorrientesCliente = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let rol = token.authorities[0]

    const [isLoad, setIsLoad] = useState(true)
    const [cuentasCorrientes, setCuentasCorrientes] = useState([])

    // PAGINATION
    const [page, setPage] = useState(0)
    const [totalRegister, setTotalRegister] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [query, setQuery] = useState('')

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack()
        if (isLoad) {
            getAllCuentasCorrientesPage(true, page, query)
                .then((res) => {
                    setCuentasCorrientes(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setPage(page)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        } else {
            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllCuentasCorrientesPage(true, page + 1, query)
            .then((res) => {
                setCuentasCorrientes(res.data.content)
                setTotalRegister(res.data.totalElements)
                setPage(page + 1)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000,
            })
            return
        }

        getAllCuentasCorrientesPage(true, page - 1, query)
            .then((res) => {
                setCuentasCorrientes(res.data.content)
                setTotalRegister(res.data.totalElements)
                setPage(page - 1)
                setTotalPage(res.data.totalPages)
                setIsLoad(false)
            })
            .catch((err) => console.log('error'))
    }

    const handleBuscador = (e) => {
        setQuery(e.target.value)
        if (e.key === 'Enter') {
            getAllCuentasCorrientesPage(true, 0, e.target.value)
                .then((res) => {
                    setPage(0)
                    setCuentasCorrientes(res.data.content)
                    setTotalRegister(res.data.totalElements)
                    setTotalPage(res.data.totalPages)
                    setIsLoad(false)
                })
                .catch((err) => console.log('error'))
        }
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faClipboardList}
                        title={'Cuentas Corrientes Clientes'}
                    />
                </div>

                <div className="d-flex bg-gris p-3 align-items-center justify-content-between">
                    <div className="d-flex">
                        <button
                            className="btn btn-outline-success btn-lg mr-2"
                            onClick={() => {
                                history.push('/add-cuenta-corriente-clientes')
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Crear
                        </button>

                        <button
                            className="btn btn-outline-primary btn-lg mr-2"
                            onClick={() => {
                                history.push(
                                    '/cuentas-corrientes-cliente-eliminada'
                                )
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} /> Eliminados
                        </button>
                        <button
                            className="btn btn-outline-info btn-lg mr-2"
                            onClick={() => {
                                history.push(
                                    '/cuentas-corrientes-informes-venta-adeudada'
                                )
                            }}
                        >
                            <FontAwesomeIcon icon={faClipboardList} /> Informes
                        </button>
                    </div>

                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">
                            <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                                type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => {
                                    handleBuscador(e)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gris p-3">
                <div className="d-flex flex-row bd-highlight">
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => previousPage()}
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo; Anterior</span>
                        </button>
                    </div>
                    <div className="p-2 bd-highlight mt-1">
                        Registros: {totalRegister} | Pagina actual: {page + 1} |
                        Total páginas : {totalPage}
                    </div>
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => nextPage()}
                            aria-label="Next"
                        >
                            <span aria-hidden="true">Siguiente &raquo;</span>
                        </button>
                    </div>
                </div>

                <table className="table table-striped mt-3 text-center">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Dni</th>
                            <th scope="col">Debe</th>
                            <th scope="col">Favor</th>
                            <th scope="col">Monto Maximo</th>
                            <th scope="col">Maximo Cuotas</th>
                            <th scope="col">Ultimo Pago</th>
                            <th scope="col">Proximo Pago</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuentasCorrientes.map((cuentaCorriente) => (
                            <ItemCuentaCorriente
                                key={parseInt(cuentaCorriente.id)}
                                cuentaCorriente={cuentaCorriente}
                                setIsLoad={setIsLoad}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default withRouter(CuentasCorrientesCliente)
