import {
    GET_CLIENTES,
    GET_CLIENTE,
    ADD_CLIENTE,
    EDIT_CLIENTE,
    DELETE_CLIENTE,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    clientes: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CLIENTES:
            return {
                ...state,
                clientes: action.payload,
            }
        case GET_CLIENTE:
            return {
                ...state,
                cliente: action.payload,
            }
        case ADD_CLIENTE:
            return {
                ...state,
                clientes: [...state.clientes, action.payload],
            }
        case EDIT_CLIENTE:
            return {
                ...state,
                clientes: state.clientes.map((cliente) =>
                    cliente.idCliente === action.payload.idCliente
                        ? (cliente = action.payload)
                        : cliente
                ),
            }
        case DELETE_CLIENTE:
            return {
                ...state,
                clientes: state.clientes.filter(
                    (cliente) =>
                        cliente.idCliente.persona.idPersona !== action.payload
                ),
            }
        default:
            return state
    }
}
