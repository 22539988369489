import React, { Fragment, useState, useEffect } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPlus, faPercent } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom'

// services
import { saveFamilias } from '../../Services/familiaService'
import { getAllDepartamentosV2 } from '../../Services/departamentoService'
import { saveError } from '../../Services/errorService'

// formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

import Swal from 'sweetalert2'
import Header from '../Header/Header'

function AddFamilia({ history }) {
    const [departamentos, setDepartamentos] = useState([])

    useEffect(() => {
        getAllDepartamentosV2(true, 0, '', 100)
            .then((res) => setDepartamentos(res.data.content))
            .catch((err) => console.error(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // validacion del formulario
    const formik = useFormik({
        initialValues: {
            nombre: '',
            ganancia: 0,
            departamento: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Obligatorio.'),
            ganancia: Yup.number()
                .required('Obligatorio.')
                .min(0, 'El numero debe ser positivo.'),
            departamento: Yup.object().required('Obligatorio.'),
        }),
        onSubmit: (values) => addFamilia(values),
    })

    const addFamilia = (values) => {
        // creamos el objeto
        let familia = {
            nombre: values.nombre,
            ganancia: values.ganancia,
            departamentoId: values.departamento.id,
        }

        // creamos la familia
        saveFamilias(familia)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar crear a una familia',
                    detalle: error.message,
                    entidad: 'FAMILIA',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    text: 'Error al crear la familia, verifique los datos.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.error(error)
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faBars} title={'Familias'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faBars} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej: Carnes..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Ganancia</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputGanancia"
                                        name="ganancia"
                                        className="form-control"
                                        placeholder="10"
                                        value={formik.values.ganancia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.ganancia &&
                                formik.errors.ganancia ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.ganancia}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputDepartamento">
                                    Departamento
                                </label>
                                <div>
                                    <Select
                                        placeholder={
                                            'Seleccionar un departamento'
                                        }
                                        options={departamentos}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'departamento',
                                                value
                                            )
                                        }
                                        getOptionValue={(values) =>
                                            values.idDepartamento
                                        }
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.departamento &&
                                formik.errors.departamento ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.departamento}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Crear Familia
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(AddFamilia)
