import {
    MOSTRAR_DEPARTAMENTOS,
    ADD_DEPARTAMENTO,
    EDIT_DEPARTAMENTO,
    GET_DEPARTAMENTO,
    DELETE_DEPARTAMENTO,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    departamentos: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MOSTRAR_DEPARTAMENTOS:
            return {
                ...state,
                departamentos: action.payload,
            }
        case GET_DEPARTAMENTO:
            return {
                ...state,
                departamento: action.payload,
            }
        case ADD_DEPARTAMENTO:
            return {
                ...state,
                departamentos: [...state.departamentos, action.payload],
            }
        case EDIT_DEPARTAMENTO:
            return {
                ...state,
                departamentos: state.departamentos.map((departamento) =>
                    departamento.idDepartamento ===
                    action.payload.idDepartamento
                        ? (departamento = action.payload)
                        : departamento
                ),
            }
        case DELETE_DEPARTAMENTO:
            return {
                ...state,
                departamentos: state.departamentos.filter(
                    (departamento) =>
                        departamento.idDepartamento !== action.payload
                ),
            }
        default:
            return state
    }
}
