import axios from 'axios'
import { url } from './global'

export const getAllDepartamentosV2 = async (
    activo,
    page,
    nombre,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/departamentos?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdDepartamentos = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/departamentos/${id}`, { headers })
    return res
}

export const editByIdDepartamentos = async (departamento) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/departamentos/${departamento.id}`,
        departamento,
        { headers }
    )
    return res
}

export const deleteByIdDepartamentos = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/departamentos/${id}`, { headers })
    return res
}

export const saveDepartamentos = async (departamento) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/departamentos`, departamento, {
        headers,
    })
    return res
}

export const recoverDepartamentoDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/departamentos/${id}/recover`,
        {},
        { headers }
    )
    return res
}
