const validationsConfig = {
    dueno: {
        required: true,
        validate: { isNull: (value) => (value === 'null' ? false : true) },
        nameForMessage: 'Cliente',
    },
    montoMaximo: {
        required: true,
        nameForMessage: 'Monto Maximo',
    },
    cantidaMaximaCuotas: {
        required: true,
        nameForMessage: 'Cantidad Maxima de Cuotas',
    },
}

export default validationsConfig
