import { ADD_TOKEN, DELETE_TOKEN } from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    token: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TOKEN:
            return {
                ...state,
                token: action.payload,
            }
        case DELETE_TOKEN:
            return {
                ...state,
                token: null,
            }
        default:
            return state
    }
}
