import {
    GET_COMPROBANTES,
    GET_COMPROBANTE,
    ADD_COMPROBANTE,
    EDIT_COMPROBANTE,
    DELETE_COMPROBANTE,
} from '../Actions/Types'

// cada reducer tiene su propio state
const initialState = {
    comprobantes: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMPROBANTES:
            return {
                ...state,
                comprobantes: action.payload,
            }
        case GET_COMPROBANTE:
            return {
                ...state,
                comprobante: action.payload,
            }
        case ADD_COMPROBANTE:
            return {
                ...state,
                comprobantes: [...state.comprobantes, action.payload],
            }
        case EDIT_COMPROBANTE:
            return {
                ...state,
                comprobantes: state.comprobantes.map((comprobante) =>
                    comprobante.idTipoComprobante ===
                    action.payload.idTipoComprobante
                        ? (comprobante = action.payload)
                        : comprobante
                ),
            }
        case DELETE_COMPROBANTE:
            return {
                ...state,
                comprobantes: state.comprobantes.filter(
                    (comprobante) =>
                        comprobante.idTipoComprobante !== action.payload
                ),
            }
        default:
            return state
    }
}
