import axios from 'axios'
import { url } from './global'

export const getAllDatoFiscales = async (
    activo,
    page,
    razonSocial,
    size = 50
) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/dato-fiscales?${razonSocial ? `razonSocial=${razonSocial}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdDatoFiscal = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/dato-fiscales/${id}`, { headers })
    return res
}

export const editByIdDatoFiscal = async (datoFiscal) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/dato-fiscales/${datoFiscal.id}`,
        datoFiscal,
        { headers }
    )
    return res
}

export const deleteByIdDatoFiscal = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/dato-fiscales/${id}`, { headers })
    return res
}

export const saveDatoFiscal = async (datoFiscal) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/dato-fiscales`, datoFiscal, {
        headers,
    })
    return res
}

export const recoverDatoFiscalDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/dato-fiscales/${id}/recover`,
        {},
        { headers }
    )
    return res
}
