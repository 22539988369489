import {
    GET_LISTAS_PRECIOS,
    GET_LISTA_PRECIO,
    ADD_LISTA_PRECIO,
    EDIT_LISTA_PRECIO,
    DELETE_LISTA_PRECIO,
} from './Types'

export const GetListasPrecios = (res) => {
    return {
        type: GET_LISTAS_PRECIOS,
        payload: res.data,
    }
}

export const AddListasPrecio = (res) => {
    return {
        type: ADD_LISTA_PRECIO,
        payload: res.data,
    }
}

export const EditaListasPrecio = (res) => {
    return {
        type: EDIT_LISTA_PRECIO,
        payload: res.data,
    }
}

export const GetListasPrecio = (res) => {
    return {
        type: GET_LISTA_PRECIO,
        payload: res.data,
    }
}

export const DeleteListasPrecio = (id) => {
    return {
        type: DELETE_LISTA_PRECIO,
        payload: id,
    }
}
