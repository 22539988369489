import axios from 'axios'
import { url } from './global'

export const getAllClientes = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/clientes?${
            nombre ? `nombre=${nombre}&` : ''
        }activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdCliente = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/clientes/${id}`, { headers })
    return res
}

export const getClienteByDni = async (dni) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/clientes/1?dni=${dni}`, { headers })
    return res
}

export const editByIdCliente = async (cliente) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(`${url}/v2/clientes/${cliente.id}`, cliente, {
        headers,
    })
    return res
}

export const deleteByIdCliente = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/clientes/${id}`, { headers })
    return res
}

export const saveCliente = async (cliente) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/clientes`, cliente, { headers })
    return res
}

export const recoverClienteDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/clientes/${id}/recover`,
        {},
        { headers }
    )
    return res
}
