import React, { Fragment, useState } from 'react'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMailBulk,
    faArrowCircleRight,
    faListAlt,
    faFile,
} from '@fortawesome/free-solid-svg-icons'

// services
import {
    sendEmail,
    sendEmailFile,
} from '../../Services/correoElectronicoService'

// routes
import { withRouter } from 'react-router-dom'

// Sweet alert
import Swal from 'sweetalert2'

const CorreoElectronico = ({ history }) => {
    //let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    const [file, setFile] = useState()
    const [destinatario, setDestinatario] = useState('')
    const [body, setBody] = useState('')
    const [asunto, setAsunto] = useState('')

    const addCorreoSubmit = (e) => {
        e.preventDefault()
        let formData
        let send = sendEmailFile
        if (file) {
            formData = new FormData()
            formData.append('archivo', file)
            formData.append('destinatario', destinatario)
            formData.append('body', body)
            formData.append('asunto', asunto)
            formData.append('nombreArchivo', asunto)
        } else {
            send = sendEmail
            formData = {
                destinatario,
                body,
                asunto,
            }
        }

        send(formData)
            .then((data) => {
                Swal.fire({
                    title: 'Correo enviado',
                    text: 'Pronto lo recibira el destinatario.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo enviar el correo.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faMailBulk}
                        title={'Envio de Correos Electronicos'}
                    />
                </div>

                <div className="bg-gris p-4">
                    <form
                        onSubmit={addCorreoSubmit}
                        encType="multipart/form-data"
                    >
                        <div className="form-row justify-content-center">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFile">Destinatario</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faMailBulk} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputDestinatario"
                                        name="destinatario"
                                        className="form-control"
                                        onChange={(e) =>
                                            setDestinatario(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputAsunto">Asunto</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faListAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputAsunto"
                                        name="asunto"
                                        className="form-control"
                                        onChange={(e) =>
                                            setAsunto(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row justify-content-center">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputBody">Mensaje</label>
                                <div className="input-group-prepend">
                                    <textarea
                                        id="inputBody"
                                        name="body"
                                        className="form-control"
                                        rows={4}
                                        cols={4}
                                        onChange={(e) =>
                                            setBody(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row justify-content-center">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFile">
                                    Seleccione un archivo
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faFile} />
                                    </span>
                                    <input
                                        type="file"
                                        id="inputFile"
                                        name="fileCSV"
                                        className="form-control"
                                        onChange={(e) =>
                                            setFile(e.target.files[0])
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faArrowCircleRight} />{' '}
                                Enviar Correo
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(CorreoElectronico)
