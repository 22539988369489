import React from 'react'

// router
import { withRouter } from 'react-router-dom'

const ItemDetalleCompra = ({ item, index }) => {
    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.articulo.nombre}</td>
            <td>{item.cantidad}</td>
            <td>${item.precio.toFixed(2)}</td>
        </tr>
    )
}

export default withRouter(ItemDetalleCompra)
