import React, { Fragment } from 'react'

// router
import { withRouter } from 'react-router-dom'

// components
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBuilding,
    faEdit,
    faShoppingCart,
    faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

// services
import { editByIdStockSucursal } from '../../Services/stockSucursalService'
import { saveError } from '../../Services/errorService'

// Sweer Alert
import Swal from 'sweetalert2'

//Formik
import { useFormik } from 'formik'
import * as Yup from 'yup'

const EditStockSucursal = ({ history, location }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))

    const formik = useFormik({
        initialValues: {
            articulo: location.state.stockSucursal.articulo,
            sucursalId: token.sucursal.id,
            cantidad: location.state.stockSucursal.cantidad,
            precioFinal: location.state.stockSucursal.precioFinal,
        },
        validationSchema: Yup.object({
            cantidad: Yup.number()
                .required('Obligatorio.')
                .min(0, 'El numero debe ser positivo.'),
            precioFinal: Yup.number()
                .required('Obligatorio.')
                .min(0, 'El numero debe ser positivo.'),
        }),
        onSubmit: (values) => editStockSucursalSubmit(values),
    })

    const editStockSucursalSubmit = (values) => {
        let stockSucursalEdit = {
            cantidad: values.cantidad,
            precioFinal: values.precioFinal,
        }
        editByIdStockSucursal(
            values.sucursalId,
            values.articulo.id,
            stockSucursalEdit
        )
            .then((res) => {
                Swal.fire({
                    position: 'top',
                    toast: true,
                    title: 'Actualizado.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                })
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje:
                        'Error al intentar modificar un articulo en el stock',
                    detalle: error.message,
                    entidad: 'STOCK_SUCURSAL',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                Swal.fire({
                    title:
                        error.response.data.message ||
                        'No se pudo modificar el stock',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faBuilding} title={'Stock Sucursales'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-column">
                            <div
                                className="form-group col-md-6"
                                style={{
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    display: 'block',
                                }}
                            >
                                <label htmlFor="inputArticuloNombre">
                                    Articulo
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputArticuloNombre"
                                        name="nombre"
                                        className="form-control"
                                        disabled
                                        value={formik.values.articulo.nombre}
                                    />
                                </div>
                            </div>

                            <div
                                className="form-group col-md-6"
                                style={{
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    display: 'block',
                                }}
                            >
                                <label htmlFor="inputArticuloCantidad">
                                    Cantidad
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon
                                            icon={faShoppingCart}
                                        />
                                    </span>
                                    <input
                                        type="number"
                                        step="any"
                                        id="inputArticuloCantidad"
                                        name="cantidad"
                                        className="form-control"
                                        value={formik.values.cantidad}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cantidad &&
                                formik.errors.cantidad ? (
                                    <small className="form-text text-primary font-weight-bold">
                                        {formik.errors.cantidad}
                                    </small>
                                ) : null}
                            </div>

                            <div
                                className="form-group col-md-6"
                                style={{
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    display: 'block',
                                }}
                            >
                                <label htmlFor="inputPrecioFinal">
                                    Precio de sucursal
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input
                                        type="number"
                                        step="any"
                                        id="inputPrecioFinal"
                                        name="precioFinal"
                                        className="form-control"
                                        placeholder={
                                            'Costo: ' +
                                            location.state.stockSucursal
                                                .articulo.costo
                                        }
                                        value={formik.values.precioFinal}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.precioFinal &&
                                formik.errors.precioFinal ? (
                                    <small className="form-text text-primary font-weight-bold">
                                        {formik.errors.precioFinal}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-danger"
                            >
                                <FontAwesomeIcon icon={faEdit} /> Editar Stock
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditStockSucursal)
