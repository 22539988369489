import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUserCircle,
    faBarcode,
    faFileInvoiceDollar,
    faCreditCard,
    faCalculator,
    faClipboardList,
    faCashRegister,
    faUserTie,
    faCubes,
    faUsers,
    faTruckMoving,
    faTruckLoading,
    faBoxes,
    faBuilding,
    faDonate,
} from '@fortawesome/free-solid-svg-icons'

// style
import './Home.css'
import Header from '../Header/Header'

const Home = ({ history }) => {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))
    let role = token.authorities[0]

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                {role === 'ROLE_ADMIN_MAGENTA' ? (
                    <Fragment>
                        <div className="row">
                            <div
                                className="text-white bg-warning col centro"
                                onClick={() => {
                                    history.push('/vender')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faCashRegister}
                                    />{' '}
                                </h1>
                                <h1>Vender</h1>
                            </div>
                            <div
                                className="text-white bg-teal col centro"
                                onClick={() => {
                                    history.push('/stock-sucursal')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faCubes} />{' '}
                                </h1>
                                <h1>Stock</h1>
                            </div>
                            <div
                                className="text-white bg-success col centro"
                                onClick={() => {
                                    history.push('/articulos')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faBarcode} />{' '}
                                </h1>
                                <h1>Articulos</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="text-white bg-danger col centro"
                                onClick={() => {
                                    history.push('/ventas')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faFileInvoiceDollar}
                                    />{' '}
                                </h1>
                                <h1>Ventas</h1>
                            </div>
                            <div
                                className="text-white bg-darkmagenta col centro"
                                onClick={() => {
                                    history.push(
                                        '/cuentas-corrientes-proveedores'
                                    )
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faTruckLoading}
                                    />{' '}
                                </h1>
                                <h1>Cuentas Corrientes Proveedores</h1>
                            </div>
                            <div
                                className="text-white bg-goldenrod col centro"
                                onClick={() => {
                                    history.push('/cuentas-corrientes-clientes')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faClipboardList}
                                    />{' '}
                                </h1>
                                <h1>Cuentas Corrientes Clientes</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="text-white bg-tomato col centro"
                                onClick={() => {
                                    history.push('/ofertas')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faDonate} />{' '}
                                </h1>
                                <h1>Ofertas</h1>
                            </div>
                            <div
                                className="text-white bg-warning col centro"
                                onClick={() => {
                                    history.push('/compras')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faBoxes} />{' '}
                                </h1>
                                <h1>Compras</h1>
                            </div>
                            <div
                                className="text-white bg-tomato col centro"
                                onClick={() => {
                                    history.push('/sucursales')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faBuilding} />{' '}
                                </h1>
                                <h1>Sucursales</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="text-white bg-info col centro"
                                onClick={() => {
                                    history.push('/clientes')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faUserCircle} />{' '}
                                </h1>
                                <h1>Clientes</h1>
                            </div>
                            <div
                                className="text-white bg-blue col centro"
                                onClick={() => {
                                    history.push('/usuarios')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faUsers} />{' '}
                                </h1>
                                <h1>Usuarios</h1>
                            </div>
                            <div
                                className="text-white bg-info col centro"
                                onClick={() => {
                                    history.push('/empleados')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faUserTie} />{' '}
                                </h1>
                                <h1>Empleados</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="text-white bg-teal col centro"
                                onClick={() => {
                                    history.push('/proveedores')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon
                                        icon={faTruckMoving}
                                    />{' '}
                                </h1>
                                <h1>Proveedores</h1>
                            </div>
                            <div
                                className="text-white bg-green col centro"
                                onClick={() => {
                                    history.push('/listas-precios')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faCalculator} />{' '}
                                </h1>
                                <h1>Listas Precios</h1>
                            </div>
                            <div
                                className="text-white bg-tomato col centro"
                                onClick={() => {
                                    history.push('/tarjetas')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faCreditCard} />{' '}
                                </h1>
                                <h1>Tarjetas</h1>
                            </div>
                        </div>
                    </Fragment>
                ) : null}
                {role === 'ROLE_CAJERO' ? (
                    <Fragment>
                        <div className="row">
                            <div
                                className="text-white bg-warning col centro"
                                onClick={() => {
                                    history.push('/compras')
                                }}
                            >
                                <h1>
                                    {' '}
                                    <FontAwesomeIcon icon={faBoxes} />{' '}
                                </h1>
                                <h1>Compras</h1>
                            </div>
                        </div>
                    </Fragment>
                ) : null}
            </div>
        </Fragment>
    )
}

export default withRouter(Home)
