import axios from 'axios'
import { url } from './global'

export const getAllRoles = async () => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(`${url}/v2/roles`, { headers })
    return res
}

export const getById = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(`${url}/roles/${id}`, { headers })
    return res
}
