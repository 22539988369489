//#region Imports

import React, { Fragment, useState, useRef, useEffect } from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCashRegister,
    faTimesCircle,
    faListAlt,
} from '@fortawesome/free-solid-svg-icons'

// component
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import Header from '../Header/Header'

// route
import { withRouter } from 'react-router-dom'

// css
import './Venta.css'

import ItemVenta from './ItemVenta'

// alert swal
import Swal from 'sweetalert2'

// services
import { getStockSucursalByCodigo } from '../../Services/stockSucursalService'
import { getAllListasPrecios } from '../../Services/listaPrecioService'
import { getAllComprobantes } from '../../Services/comprobanteService'
import { getLastNroComprobante } from '../../Services/ventaService'
import { getClienteByDni } from '../../Services/clienteService'
import { savePresupuesto } from '../../Services/presupuestoService'

// functions
import { determinarTipoComprobante } from './Helpers'

// modals
import ClientesModalVenta from './ClienteModalVentas/ClientesModalVenta'
import ModalArticulosVenta from './ArticuloModalVentas/ModalArticuloVenta'
import FinalizarVentaModal from './FinalizarModalVentas/FinalizarVentaModal'

//#endregion

function ConsolaVenta({ history }) {
    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]))

    const inputTotalRef = useRef(null)
    const fecha = new Date().toISOString().split('T')[0]
    const [isLoad, setIsLoad] = useState(true)
    const [show, setShow] = useState(false)

    // datos de factura
    const [cliente, setCliente] = useState({ id: { persona: { dni: '' } } })
    const [numeroComprobante, setNumeroComprobante] = useState('')
    const [itemsFactura, setItemsFactura] = useState([])

    // Items y total
    const [codigoAndCantidad, setCodigoAndCantidad] = useState('')
    const [items, setItems] = useState([])
    const [total, setTotal] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [descuento, setDescuento] = useState(0)

    // consultar lista precio para dejar seteada la que se usara
    const [listasPrecios, setListasPrecios] = useState([])
    const [listaPrecio, setListaPrecio] = useState(1)

    // seteamos el comprobante mas usado
    const [tiposComprobantes, setTiposComprobantes] = useState([])
    const [tipoComprobante, setTipoComprobante] = useState(3)

    // puntos obtenidos del cliente
    const [puntosArticulo, setPuntosArticulo] = useState(0)

    // Custom swal
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
    })

    //Hook de efecto para cargar.
    useEffect(() => {
        if (isLoad) {
            const ptoVenta = sessionStorage.getItem('ptoventa')

            if (!ptoVenta) history.push('./apertura-caja')

            inputTotalRef.current.focus()

            // cargo el cliente por defecto
            getClienteByDni(9999999)
                .then(async (data) => {
                    setCliente(data.data)
                    setListaPrecio(data.data.listaPrecio.id)
                    determinarTipoComprobante(
                        data.data.condicionFiscal.id,
                        setTipoComprobante
                    )
                })
                .catch((error) => {
                    console.log(error.response)
                })

            // cargo listas de precios
            getAllListasPrecios(true, 0, '', 1000)
                .then((res) => {
                    setListasPrecios(res.data.content)
                })
                .catch((error) => console.log(error.response))

            // cargo los comprobantes
            getAllComprobantes()
                .then((res) => {
                    setTiposComprobantes(res.data)
                })
                .catch((error) => console.log(error.response))

            getLastNroComprobante()
                .then(async (res) => {
                    setNumeroComprobante(res.data.id + 1)
                })
                .catch((error) => console.log(error.response))

            setIsLoad(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])

    const reset = () => {
        setItems([])
        setItemsFactura([])
        setTotal(0)
        setSubtotal(0)
        setDescuento(0)
        getClienteByDni(9999999)
            .then(async (data) => {
                setCliente(data.data)
                setListaPrecio(data.data.listaPrecio.id)
                determinarTipoComprobante(
                    data.data.condicionFiscal.id,
                    setTipoComprobante
                )
            })
            .catch((error) => console.log(error.response))
        getLastNroComprobante()
            .then(async (res) => {
                setNumeroComprobante(res.data.id + 1)
            })
            .catch((error) => console.log(error.response))
    }

    // manejo de input cuando agregan articulos
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            let cantidad = 1
            let codigoArticulo

            if (codigoAndCantidad.toString().indexOf('*') !== -1) {
                let valores = codigoAndCantidad.split('*')
                cantidad = parseInt(valores[0])
                codigoArticulo = valores[1]
            } else {
                codigoArticulo = codigoAndCantidad
            }

            // Si se cumple el codigo viene de balanza.
            if (
                codigoArticulo.toString().indexOf('20') === 0 &&
                codigoArticulo.length >= 12
            ) {
                let codigo = parseFloat(codigoArticulo.slice(2, 6), 10)
                let precio = parseFloat(codigoArticulo.slice(6, 12), 10) / 100

                getStockSucursalByCodigo(token.sucursal.id, codigo, listaPrecio)
                    .then((data) => {
                        // datos para calcular precio articulo
                        let articulo = data.data.articulo
                        let descuento_articulo = 0

                        // calculamos los gramos que lleva para descontar del stock
                        let precioKilo = articulo.precioFinal
                            ? parseFloat(articulo.precioFinal)
                            : parseFloat(articulo.precioFinalCalculado)
                        let gramosLLeva = parseFloat(
                            (precio * 1000.0) / precioKilo
                        )

                        // verifico si hay stock
                        if (gramosLLeva >= parseFloat(data.data.cantidad)) {
                            Toast.fire({
                                title: 'No hay stock disponible para esa cantidad',
                                type: 'info',
                            })
                        }

                        let item = {
                            id: articulo.id,
                            codigo: articulo.codigo,
                            articulo: articulo.nombre,
                            cantidad: gramosLLeva.toFixed(0),
                            precio: precio.toFixed(2),
                            iva: articulo.familia.departamento.tasaIva,
                            descuento: 0,
                            pesable: true,
                        }

                        // items para guardar en la base de datos
                        let itemFactura = {
                            articulo: articulo,
                            cantidad: gramosLLeva.toFixed(0),
                            precio: precio.toFixed(2),
                            descuento: 0,
                        }

                        // agregamos puntos obtenidos
                        setPuntosArticulo(
                            puntosArticulo + parseInt(articulo.puntos * 1)
                        )

                        // ingresamos items que se guardaran en la bd
                        setItemsFactura([...itemsFactura, itemFactura])
                        setItems([...items, item])

                        setTotal(
                            total +
                                (itemFactura.precio -
                                    item.precio * descuento_articulo)
                        )
                        setSubtotal(subtotal + parseFloat(item.precio))

                        setDescuento(
                            descuento +
                                parseFloat(
                                    itemFactura.precio * descuento_articulo
                                )
                        )
                        //if (item.iva === 0) setIva0(iva0 + parseFloat(item.iva0 * item.cantidad));

                        setCodigoAndCantidad('')
                        inputTotalRef.current.focus()
                    })

                    .catch((error) => {
                        Toast.fire({
                            title: 'El codigo del articulo no se encontro',
                            type: 'error',
                        })
                        setCodigoAndCantidad('')
                        inputTotalRef.current.focus()
                    })
            }

            //Si no se cumple entonces se introdujo por codigo de articulo o codigo de barra
            else {
                // verifico que se ingreso una cantidad correcta
                if (isNaN(parseInt(cantidad))) {
                    setCodigoAndCantidad('')
                    inputTotalRef.current.focus()
                    Toast.fire({
                        title: 'Debe ingresar una cantidad valida',
                        type: 'info',
                    })
                    return
                }

                getStockSucursalByCodigo(
                    token.sucursal.id,
                    codigoArticulo,
                    listaPrecio
                )
                    .then((data) => {
                        // datos para calcular precio articulo
                        let articulo = data.data.articulo
                        let descuento_articulo = 0
                        let isPresent = false

                        // verfico si es pesable
                        if (articulo.pesable === true) {
                            if (cantidad === 1) cantidad = 1000 // Paso la cantidad a gramos.
                            let precioKilo = articulo.precioFinal
                                ? parseFloat(articulo.precioFinal)
                                : parseFloat(articulo.precioFinalCalculado)

                            let precioCantidad = (
                                (precioKilo / 1000) *
                                cantidad
                            ).toFixed(2)

                            let item = {
                                id: articulo.id,
                                codigo: articulo.codigo,
                                articulo: articulo.nombre,
                                cantidad: cantidad,
                                precio: precioCantidad,
                                iva: articulo.familia.departamento.tasaIva,
                                descuento: 0,
                                pesable: true,
                            }

                            // items para guardar en la base de datos
                            let itemFactura = {
                                articulo: articulo,
                                cantidad: cantidad,
                                precio: precioCantidad,
                                descuento: 0,
                            }

                            // ingresamos items que se guardaran en la bd
                            setItemsFactura([...itemsFactura, itemFactura])
                            setItems([...items, item])

                            setTotal(
                                total +
                                    (precioCantidad -
                                        precioCantidad * descuento_articulo)
                            )
                            setSubtotal(subtotal + parseFloat(precioCantidad))
                            setDescuento(
                                descuento +
                                    parseFloat(
                                        precioCantidad * descuento_articulo
                                    )
                            )

                            setCodigoAndCantidad('')
                            inputTotalRef.current.focus()
                            return
                        }

                        items.forEach((item) => {
                            if (
                                parseInt(item.codigo, 10) ===
                                parseInt(articulo.codigo, 10)
                            ) {
                                item.cantidad += cantidad
                                isPresent = true
                            }
                        })

                        // si ya se encuentra presente actualizo cantidad en ItemFactura y retorno
                        if (isPresent) {
                            itemsFactura.forEach((itemFactura) => {
                                if (
                                    parseInt(
                                        itemFactura.articulo.codigo,
                                        10
                                    ) === parseInt(articulo.codigo, 10)
                                ) {
                                    itemFactura.cantidad += cantidad
                                }
                            })

                            // agregamos puntos obtenidos
                            setPuntosArticulo(
                                puntosArticulo +
                                    parseInt(articulo.puntos * cantidad)
                            )

                            let price = articulo.precioFinal
                                ? parseFloat(articulo.precioFinal)
                                : parseFloat(articulo.precioFinalCalculado)

                            setTotal(
                                total +
                                    (price * cantidad -
                                        price * cantidad * descuento_articulo)
                            )
                            setSubtotal(subtotal + parseFloat(price * cantidad))
                            setDescuento(
                                descuento +
                                    parseFloat(price * descuento_articulo)
                            )

                            setCodigoAndCantidad('')
                            inputTotalRef.current.focus()
                            return
                        }

                        if (
                            parseFloat(cantidad) >=
                                parseFloat(data.data.cantidad) &&
                            articulo.compuesto === false
                        ) {
                            Toast.fire({
                                title: 'Advertencia. No hay stock.',
                                type: 'info',
                            })
                        }

                        let item = {
                            id: articulo.id,
                            codigo: articulo.codigo,
                            articulo: articulo.nombre,
                            cantidad: cantidad,
                            precio: articulo.precioFinal
                                ? articulo.precioFinal.toFixed(2)
                                : articulo.precioFinalCalculado.toFixed(2),
                            iva: articulo.familia.departamento.tasaIva,
                            descuento: 0,
                            pesable: false,
                        }

                        // items para guardar en la base de datos
                        let itemFactura = {
                            articulo: articulo,
                            cantidad: cantidad,
                            precio: articulo.precioFinal
                                ? articulo.precioFinal.toFixed(2)
                                : articulo.precioFinalCalculado.toFixed(2),
                            descuento: 0,
                        }

                        // agregamos puntos obtenidos
                        setPuntosArticulo(
                            puntosArticulo +
                                parseInt(articulo.puntos * item.cantidad)
                        )

                        // ingresamos items que se guardaran en la bd
                        setItemsFactura([...itemsFactura, itemFactura])
                        setItems([...items, item])

                        setTotal(
                            total +
                                (itemFactura.precio * itemFactura.cantidad -
                                    itemFactura.precio *
                                        itemFactura.cantidad *
                                        descuento_articulo)
                        )
                        setSubtotal(
                            subtotal +
                                parseFloat(
                                    itemFactura.precio * itemFactura.cantidad
                                )
                        )

                        setDescuento(
                            descuento +
                                parseFloat(
                                    itemFactura.precio * descuento_articulo
                                )
                        )

                        setCodigoAndCantidad('')
                        inputTotalRef.current.focus()
                    })
                    .catch((error) => {
                        console.log(error)
                        Toast.fire({
                            title: 'El codigo del articulo no se encontro',
                            type: 'info',
                        })
                        setCodigoAndCantidad('')
                        inputTotalRef.current.focus()
                    })
            }
        }
    }

    // elimina items de la lista de articulos
    const deleteItem = (articulo, key) => {
        let itemsDelete = items.filter((item, index) => {
            return (
                parseInt(item.id, 10) !== parseInt(articulo.id, 10) ||
                key !== index
            )
        })

        let itemsFacturaDelete = itemsFactura.filter((item, index) => {
            return (
                parseInt(item.articulo.id, 10) !== parseInt(articulo.id, 10) ||
                key !== index
            )
        })

        setItemsFactura(itemsFacturaDelete)
        setItems(itemsDelete)

        if (articulo.pesable) {
            setTotal(total - parseFloat(articulo.precio))
            setSubtotal(subtotal - parseFloat(articulo.precio))

            setDescuento(
                descuento -
                    parseFloat(articulo.precio * (articulo.descuento / 100))
            )
            setPuntosArticulo(
                puntosArticulo - parseInt(articulo.articulo.puntos)
            )
        } else {
            setTotal(total - parseFloat(articulo.precio * articulo.cantidad))
            setSubtotal(
                subtotal - parseFloat(articulo.precio * articulo.cantidad)
            )

            setDescuento(
                descuento -
                    parseFloat(articulo.precio * (articulo.descuento / 100))
            )
            setPuntosArticulo(
                puntosArticulo -
                    parseInt(articulo.articulo.puntos * articulo.cantidad)
            )
        }

        inputTotalRef.current.focus()
    }

    //Para crear un presupuesto
    const createPresupuesto = () => {
        if (cliente.id.persona.dni.length <= 0 || itemsFactura.length === 0) {
            Swal.fire({
                title: 'No se puede generar un presupuesto vacio.',
                type: 'info',
                showConfirmButton: false,
                timer: 1500,
            })
            return
        }

        let today = new Date()

        let presupuesto = {
            total: parseFloat(total).toFixed(2),
            tipoComprobanteId: tipoComprobante,
            clienteId: cliente.id.persona.id,
            items: itemsFactura,
            sucursalId: token.sucursal.id,
            fechaVencimiento: today.setDate(today.getDate() + 14),
        }

        savePresupuesto(presupuesto)
            .then((data) => {
                Swal.fire({
                    position: 'top',
                    toast: true,
                    title: 'Presupuesto creado.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Faltan datos, no se pudo crear el presupuesto',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faCashRegister} title={'Venta'} />
                </div>

                <div className="bg-gris centrado">
                    <div className="screen">
                        <p>SUBTOTAL: ${total.toFixed(2)}</p>
                    </div>

                    <div>
                        {/* BUSQUEDA DE CLIENTES, TIPO COMPROBANTE, NRO COMPROBANTE, FECHA */}
                        <div className="d-flex bd-highlight">
                            <div className="p-2 bd-highlight">
                                <label htmlFor="inputNumero">Cliente</label>
                                <div className="input-group-prepend">
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        defaultValue={cliente.id.persona.dni}
                                        disabled
                                        placeholder="Ingrese cliente"
                                    />
                                    <ClientesModalVenta
                                        setListaPrecio={setListaPrecio}
                                        setTipoComprobante={setTipoComprobante}
                                        setCliente={setCliente}
                                        determinarTipoComprobante={
                                            determinarTipoComprobante
                                        }
                                    />
                                </div>
                            </div>
                            <div className="p-2 bd-highlight"></div>
                            <div className="p-2 bd-highlight">
                                <label htmlFor="inputComprobante">
                                    Tipo Comprobante
                                </label>
                                <select
                                    id="inputComprobante"
                                    className="custom-select"
                                    name="comprobante"
                                    value={tipoComprobante}
                                    onChange={(e) =>
                                        setTipoComprobante(e.target.value)
                                    }
                                >
                                    {tiposComprobantes.map((comprobante) => (
                                        <option
                                            key={comprobante.id}
                                            value={comprobante.id}
                                        >
                                            {comprobante.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="p-2 bd-highlight">
                                <label htmlFor="inputNroComprobante">
                                    Nro Comprobante
                                </label>
                                <div className="input-group-prepend">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="inputNroComprobante"
                                        value={numeroComprobante}
                                        disabled
                                        name="numeroComprobante"
                                    />
                                </div>
                            </div>
                            <div className="p-2 bd-highlight">
                                <label htmlFor="inputFecha">Fecha</label>
                                <div className="input-group-prepend">
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="inputFecha"
                                        value={fecha}
                                        disabled
                                        name="fecha"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* BUSQUEDA DE ARTICULO, INGRESO CODIGO, LISTA DE PRECIOS */}
                        <div className="d-flex bd-highlight">
                            <div className="p-2 bd-highlight">
                                <ModalArticulosVenta
                                    setCodigoAndCantidad={setCodigoAndCantidad}
                                    inputTotalRef={inputTotalRef}
                                    listaPrecio={listaPrecio}
                                />
                            </div>
                            <div className="p-2 flex-grow-1 bd-highlight">
                                <input
                                    className="form-control form-control-lg"
                                    style={{ height: 45 }}
                                    type="text"
                                    placeholder="Ingrese un articulo"
                                    value={codigoAndCantidad}
                                    ref={inputTotalRef}
                                    id="inputArticulo"
                                    onChange={(e) =>
                                        setCodigoAndCantidad(e.target.value)
                                    }
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />
                            </div>
                            <div className="p-2 flex-grow-1 bd-highlight">
                                <select
                                    id="inputListaPrecio"
                                    className="custom-select"
                                    name="listaPrecio"
                                    style={{ height: 45 }}
                                    value={listaPrecio}
                                    onChange={(e) =>
                                        setListaPrecio(e.target.value)
                                    }
                                >
                                    {listasPrecios.map((lp, index) => (
                                        <option key={index} value={lp.id}>
                                            {lp.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* TABLA DE ARTICULOS INGRESADOS */}
                        <div className="table-wrapper">
                            <table className="table table-hover">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Codigo</th>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Cant.</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">IVA</th>
                                        <th scope="col">Descuento</th>
                                        <th scope="col">Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items
                                        ? items.map((item, index) => (
                                              <ItemVenta
                                                  key={parseInt(index)}
                                                  index={index}
                                                  articulo={item}
                                                  borrar={deleteItem}
                                              />
                                          ))
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        {/* SUBTOTAL, DESCUENTO, TOTAL */}
                        <table className="table centrado">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">SUBTOTAL</th>
                                    <th scope="col">DESCUENTO</th>
                                    <th scope="col">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>${subtotal.toFixed(2)}</td>
                                    <td>${descuento.toFixed(2)}</td>
                                    <td>${total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>

                        {/* BOTONES CANCELAR, PRESUPUESTO Y COBRAR */}
                        <div className="row justify-content-center">
                            <button
                                className="btn btn-outline-primary btn-lg col mr-1 ml-1"
                                onClick={() => reset()}
                            >
                                Cancelar Venta{' '}
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                            <button
                                className="btn btn-outline-warning btn-lg col mr-1 ml-1"
                                onClick={() => createPresupuesto()}
                            >
                                Crear Presupuesto{' '}
                                <FontAwesomeIcon icon={faListAlt} />
                            </button>

                            {/* <button> */}
                            <FinalizarVentaModal
                                ventaDatos={{
                                    total,
                                    subtotal,
                                    descuento,
                                    numeroComprobante,
                                    tipoComprobante,
                                    cliente,
                                    itemsFactura,
                                }}
                                isLoad={isLoad}
                                show={show}
                                handleShow={handleShow}
                                handleClose={handleClose}
                                reset={reset}
                            />
                            {/* </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(ConsolaVenta)
