import axios from 'axios'
import { url } from './global'

export const getAllArticulos = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.get(
        `${url}/v2/articulos?${nombre ? `nombre=${nombre}&` : ''}activo=${activo}&page=${page}&size=${size}`,
        { headers }
    )
    return res
}

export const getByIdArticulos = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/articulos/${id}`, { headers })
    return res
}

export const getArticuloByCodigo = async (codigo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/v2/articulos/1?codigo=${codigo}`, {
        headers,
    })
    return res
}

export const getAllArticulosByNombre = async (valor) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.get(`${url}/articulos-like/${valor}`, { headers })
    return res
}

export const editByIdArticulos = async (articulo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.put(
        `${url}/v2/articulos/${articulo.id}`,
        articulo,
        { headers }
    )
    return res
}

export const deleteByIdArticulos = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.delete(`${url}/v2/articulos/${id}`, { headers })
    return res
}

export const saveArticulos = async (articulo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/articulos`, articulo, { headers })
    return res
}

export const saveArticulosCompuestos = async (articulo) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/articulos/compuestos`, articulo, {
        headers,
    })
    return res
}

// IMPORT ARTICULOS
export const saveArticulosImportar = async (data) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'multipart/file',
        Authorization: 'Bearer '.concat(token),
    }
    const res = await axios.post(`${url}/v2/articulos/importar`, data, {
        headers,
    })
    return res
}

export const recoverArticulosDeleted = async (id) => {
    let token = sessionStorage.getItem('token')
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer '.concat(token),
    }
    let res = await axios.put(
        `${url}/v2/articulos/${id}/recover`,
        {},
        { headers }
    )
    return res
}
