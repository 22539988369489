import axios from 'axios'
import { url_api_afip_dev } from './global'

export const getPuntosVenta = async () => {
    let apiKey = sessionStorage.getItem('api-key')
    let headers = {
        'Content-Type': 'application/json',
        'api-key': apiKey,
    }
    let res = await axios.get(`${url_api_afip_dev}/sales-point`, { headers })
    return res
}

export const getTipoComprobantes = async () => {
    let apiKey = sessionStorage.getItem('api-key')
    let headers = {
        'Content-Type': 'application/json',
        'api-key': apiKey,
    }
    let res = await axios.get(`${url_api_afip_dev}/voucher-types`, { headers })
    return res
}

export const getTipoConceptos = async () => {
    let apiKey = sessionStorage.getItem('api-key')
    let headers = {
        'Content-Type': 'application/json',
        'api-key': apiKey,
    }
    let res = await axios.get(`${url_api_afip_dev}/concept-types`, { headers })
    return res
}

export const getTipoDocumentos = async () => {
    let apiKey = sessionStorage.getItem('api-key')
    let headers = {
        'Content-Type': 'application/json',
        'api-key': apiKey,
    }
    let res = await axios.get(`${url_api_afip_dev}/document-types`, { headers })
    return res
}
