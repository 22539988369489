import React, { Fragment, useEffect, useState } from 'react'
import BotonBack from '../Utiles/BotonBack'
import Subtitulo from '../Utiles/Subtitulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

// services
import { editUsuario } from '../../Services/usuarioService'
import { getAllRoles } from '../../Services/roleService'
import { saveError } from '../../Services/errorService'
import { getAllSucursales } from '../../Services/sucursalService'

// components
import Swal from 'sweetalert2'
import Header from '../Header/Header'

// Formik
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

const EditUsuario = ({ history, location }) => {
    const usuario = location.state.usuario

    const [roles, setRoles] = useState([])
    const [sucursales, setSucursales] = useState([])

    useEffect(() => {
        getAllRoles()
            .then((res) => setRoles(res.data.content))
            .catch((err) => console.error(err))
        getAllSucursales(true, 0)
            .then((res) => setSucursales(res.data.content))
            .catch((err) => console.error(err))
    }, [])

    const formik = useFormik({
        initialValues: {
            nombre: usuario.usuario.nombre,
            apellido: usuario.usuario.apellido,
            password: '',
            passwordConfirm: '',
            roleId: usuario.roles[0].id.role.id,
            sucursalId: usuario.roles[0].sucursal
                ? usuario.roles[0].sucursal.id
                : '',
            role: usuario.roles[0].id.role,
            sucursal: usuario.roles[0].sucursal
                ? usuario.roles[0].sucursal
                : {},
        },
        validationSchema: Yup.object({
            password: Yup.string().min(4, 'Debe tener al menos 4 caracteres'),
            passwordConfirm: Yup.string().when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Las contraseñas no son iguales'
                ),
            }),
            roleId: Yup.number().required('El rol es requerido'),
            sucursalId: Yup.number(),
        }),
        onSubmit: (values) => editUsuarioSubmit(values),
    })

    const editUsuarioSubmit = ({
        nombre,
        apellido,
        password,
        passwordConfirm,
        roleId,
        sucursalId,
    }) => {
        let usuarioRole = {
            nombre,
            apellido,
            password,
            passwordConfirm,
            roleId,
            sucursalId,
            id: usuario.id,
        }

        editUsuario(usuarioRole)
            .then((res) => {
                history.goBack()
            })
            .catch(async (error) => {
                const errorData = {
                    mensaje: 'Error al intentar actualizar un usuario',
                    detalle: error.message,
                    entidad: 'USUARIO',
                    tipo: 'WEBAPP',
                    severidad: 'HIGH',
                    reportado: 'APPLICATION',
                }

                await saveError(errorData)
                console.log(error)
                Swal.fire({
                    text: 'No se pudieron actualizar los datos.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faUser} title={'Editar usuario'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Nombre"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre &&
                                formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputApellido">Apellido</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputApellido"
                                        name="apellido"
                                        className="form-control"
                                        placeholder="Apellido"
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.apellido &&
                                formik.errors.apellido ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.apellido}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPass">Contraseña</label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserSecret} />
                                    </span>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="inputPass"
                                        name="password"
                                        placeholder="********"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.password &&
                                formik.errors.password ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.password}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputPassConfirm">
                                    Confirmar contraseña
                                </label>
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="inputGroupPrepend"
                                    >
                                        <FontAwesomeIcon icon={faUserSecret} />
                                    </span>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="inputPasswordConfirm"
                                        name="passwordConfirm"
                                        placeholder="********"
                                        value={formik.values.passwordConfirm}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.passwordConfirm &&
                                formik.errors.passwordConfirm ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.passwordConfirm}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputRol">Rol</label>
                                <div>
                                    <Select
                                        placeholder={'Seleccionar un rol'}
                                        options={roles}
                                        defaultValue={formik.values.role}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'roleId',
                                                value.id
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.roleId &&
                                formik.errors.roleId ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.roleId}
                                    </small>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputSucursal">Sucursal</label>
                                <div>
                                    <Select
                                        placeholder={'Seleccionar una sucursal'}
                                        options={sucursales}
                                        defaultValue={formik.values.sucursal}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'sucursalId',
                                                value.id
                                            )
                                        }
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) =>
                                            values.nombre
                                        }
                                    ></Select>
                                </div>
                                {formik.touched.sucursalId &&
                                formik.errors.sucursalId ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.sucursalId}
                                    </small>
                                ) : null}
                            </div>
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Editar usuario
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(EditUsuario)
